<template>
  <div ref="development" class="container">
    <div class="title">发展性分析</div>

    <examTransformVue
      :stat-id="$route.query.id"
      :check-list="checkList"
      :exam-arr="examArr"
      new-exam
      is-ap
      style="margin-bottom: 18px"
      @submit="getExamId"
    />

    <div class="btns">
      <div
        v-for="(item, index) in subjectList"
        :key="index"
        :class="['btns-item', subjectId === item.id ? 'btns-active' : '']"
        @click="subjectClick(item)"
      >
        {{ item.name }}
      </div>
    </div>
    <div v-if="subjectId == 0" class="btns">
      <a-select
        v-model="category"
        not-found-content="暂无数据"
        style="margin-bottom: 18px; margin-right: 8px; width: 180px"
        @change="categoryChange"
      >
        <a-select-option v-if="$parent.topData.hasPhysics == 1" :value="1">
          物理类
        </a-select-option>
        <a-select-option v-if="$parent.topData.hasHistory == 1" :value="2">
          历史类
        </a-select-option>
      </a-select>
      <div
        v-for="(item, index) in electives"
        :key="index"
        :class="['btns-item', electiveId === item.id ? 'btns-active' : '']"
        @click="electiveClick(item)"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="operate">
      <div class="params">
        <a-select
          v-if="dataType !== '1'"
          :key="dataType"
          v-model="params"
          style="width: 200px"
          not-found-content="暂无数据"
          @change="paramsChange"
        >
          <a-select-option
            v-for="(item, index) in options"
            :key="index"
            :value="item.name"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
        <a-radio-group
          v-model="dataSource"
          style="margin-left: 8px"
          button-style="solid"
          @change="dataSourceChange"
        >
          <a-radio value="1"> 原始分 </a-radio>
          <a-radio v-if="![1, 2, 3, 4, 8].includes(subjectId)" value="2">
            赋分</a-radio
          >
        </a-radio-group>
      </div>
      <a-radio-group
        v-model="dataType"
        button-style="solid"
        @change="dataTypeChange"
      >
        <a-radio-button value="1"> 超均率 </a-radio-button>
        <a-radio-button value="2"> 比率 </a-radio-button>
        <a-radio-button v-if="topSections.length" value="3">
          前N名
        </a-radio-button>
      </a-radio-group>
    </div>

    <chart
      v-if="defineOptions && series.length"
      :key="chartKey"
      :define-options="defineOptions"
      :series="series"
    />
    <no-data v-else />
    <a-table
      :columns="columns"
      :data-source="tableData"
      bordered
      style="margin-bottom: 24px"
      :pagination="false"
      :scroll="{ x: 'max-content' }"
    >
      <template slot="oriRate">
        <div>原始分</div>
      </template>
    </a-table>
    <div class="tip">
      <div class="intent">注：</div>
      <div>
        <div>折线图——班级历次考试指标值</div>
        <div>
          1.
          同色折线不同考试节点对比：最近一次考试与历次考试进行对比，用于查看班级考试指标的变化趋势。
        </div>
        <div>
          2.
          同一考试节点不同颜色值对比：每次考试各班级间指标的差距。可选择同比、环比及周期性对比的角度进行发展性的分析。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import examTransformVue from "../../components/examTransform.vue";
import { examTypeOptions } from "@/core/util/constdata";
import chart from "../../components/charts.vue";
// import classNums from "../../components/classNums.vue";
import {
  getstatsubjectoption,
  getstateleccomboptionV2,
} from "@/core/api/academic/common";
import { ortdevelopanalysis } from "@/core/api/newExamAcademic/union/index";
export default {
  name: "Development",
  components: {
    examTransformVue,
    chart,
    // classNums,
  },
  data() {
    return {
      defineOptions: null,
      series: [],
      type: "2",
      examList: [],
      visible: false,
      confirmLoading: false,
      examTypeOptions: examTypeOptions,
      examTypes: [],
      category: null,
      subjectList: [],
      electiveId: null,
      checkList: [],
      electives: [],
      dataSource: "1",
      subjectId: 0,
      // classNum: null,
      // classNumList: [],
      // classNumKey: Math.random(), 冯参说去掉 2024/07/24
      compareList: [],
      examArr: [],
      dataType: "1",
      params: "",
      options: [],
      chartKey: Math.random(),
      columns: [],
      tableData: [],
      ratios: [],
      topSections: [],
    };
  },
  mounted() {
    if (this.$parent.topData.hasPhysics == 1) {
      this.category = 1;
    } else if (this.$parent.topData.hasHistory == 1) {
      this.category = 2;
    }
    this.getSubectList();
  },
  methods: {
    selectChange() {},
    getExamId(val) {
      this.compareIds = val;
      this.getData();
    },
    dataSourceChange() {
      if (this.examArr.length) {
        this.defineOptions = {
          legend: {
            itemGap: 20,
            type: "scroll",
            orient: "horizontal",
          },
          xAxis: {
            type: "category",
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: true,
              interval: 0,
              width: 100,
              overflow: "breakAll",
            },
            data: [...this.examArr.map((item) => item.name)],
          },
        };
        let schoolVOS = this.examArr[0].schoolVOS;
        const data = this.examArr.flatMap((item) => item.schoolVOS);
        if (this.dataType == "1") {
          const schoolData = schoolVOS.filter(
            (item) => item.schoolName != "整体"
          );
          this.series = schoolData.map((item) => {
            const sameClass = data.filter(
              (it) => it.schoolName === item.schoolName
            );
            return {
              name: "超均率（校）",
              type: "line",
              label: {
                show: true,
                textStyle: {
                  color: "inherit",
                  fontSize: 9,
                },
                position: "top",
              },
              data: sameClass.map(
                (item) =>
                  item[
                    this.dataSource == 1
                      ? "overAverageRateOri"
                      : "overAverageRate"
                  ] || 0
              ),
            };
          });
        } else if (this.dataType == "2") {
          if (schoolVOS.length) {
            if (schoolVOS[0].ratios && schoolVOS[0].ratios.length) {
              this.options = schoolVOS[0].ratios;
              this.params = this.options[0].name;
              this.setchart("ratios");
            }
          }
        } else if (this.dataType == "3") {
          if (schoolVOS.length) {
            if (schoolVOS[0].topSections && schoolVOS[0].topSections.length) {
              this.options = schoolVOS[0].topSections;
              this.params = this.options[0].name;
              this.setchart("topSections");
            }
          }
        }
      }
      this.chartKey = Math.random();
    },
    async getSubectList() {
      const res = await getstatsubjectoption({
        id: this.$route.query.id,
        requestPage: 7,
        hasTotal: 1,
        viewSchoolId: this.$route.query.schoolId,
      });
      this.subjectList = res.data.data;
      if (res.data.data.length) {
        this.subjectId = res.data.data[0].id;
        // this.getClassList();
        this.categoryChange();
      }
    },
    async geteleccomboption() {
      let obj = {
        1: 98,
        2: 99,
      };
      const res = await getstateleccomboptionV2({
        id: this.$route.query.id,
        hasCate: 1,
        subjectId: this.subjectId,
        eleccateId: obj[this.category],
        viewSchoolId: this.$route.query.schoolId,
      });
      this.electives = res.data.data;

      if (this.electives.length) {
        this.electiveId = this.electives[0].id;
        this.getData();
      }
    },
    categoryChange() {
      this.geteleccomboption();
    },

    async getCondition(loadingInstance, io) {
      const res = await getstatsubjectoption({
        id: this.$route.query.id,
        requestPage: 1,
        viewSchoolId: this.$route.query.schoolId,
        hasTotal: 1,
      }).catch(() => this.closeLoading(loadingInstance, io));
      this.electives = res.data.data;
      this.getClassList();
      this.closeLoading(loadingInstance, io);
    },

    closeLoading(loadingInstance, io) {
      if ((loadingInstance, io)) {
        this.$nextTick(() => {
          loadingInstance.close();
          io.unobserve(this.$refs.development);
        });
      }
    },
    async getData() {
      const res = await ortdevelopanalysis({
        id: this.$route.query.id,
        contrastStatId: this.compareIds ? this.compareIds.join(",") : "",
        eleccombId: this.electiveId || null,
        subjectId: this.subjectId,
        viewSchoolId: this.$route.query.schoolId,
      });
      this.examArr = res.data.data;
      let result = res.data.data.flatMap((item) => {
        return item.schoolVOS.map((it) => ({
          name: item.name,
          id: item.id,
          ...it,
        }));
      });
      if (this.$parent.topData.isSchoolDisplayReportUnionAverageScore != 1) {
        result = result.filter((item) => item.schoolName != "整体");
      }
      this.tableData = result;
      if (this.tableData.length) {
        this.ratios = this.tableData[0].ratios;
        this.topSections = this.tableData[0].topSections || [];
      }
      this.checkList = res.data.data.map((item) => item.id);
      this.dataTypeChange();
    },
    paramsChange() {
      const enmu = {
        2: "ratios",
        3: "topSections",
      };
      let key = enmu[this.dataType];
      this.setchart(key);
    },
    dataTypeChange() {
      this.defineOptions = null;
      this.options = [];
      this.columns = [];
      this.series = [];
      this.params = null;
      const funEnmu = {
        1: () => this.setOveravg(),
        2: () => this.setRatios(),
        3: () => this.setTopSections(),
      };
      funEnmu[this.dataType]();
      this.dataSourceChange();
    },
    setOveravg() {
      this.columns = [
        {
          title: "考试名称",
          dataIndex: "name",
          align: "center",
          customRender: (text, record, index) => {
            if (
              this.$parent.topData.isSchoolDisplayReportUnionAverageScore == 1
            ) {
              const obj = {
                children: text,
                attrs: {
                  rowSpan: index % 2 == 0 ? 2 : 0,
                },
              };
              return obj;
            }
            return text;
          },
        },
        {
          title: "学校",
          dataIndex: "schoolName",
          align: "center",
        },
        {
          title: "参统考生",
          dataIndex: "statTotal",
          align: "center",
        },
        {
          title: "平均分",
          dataIndex: "name",
          align: "center",
          children: [
            {
              title: "原始分",
              dataIndex: "averageScoreOri",
              align: "center",
            },
            {
              title: "排名",
              dataIndex: "averageScoreOriRank",
              align: "center",
              customRender: (text, record) =>
                this.$parent.topData.isSchoolDisplayReportSchoolRank == 1
                  ? record.averageScoreOriRank
                  : "-",
            },
            {
              title: "赋分",
              dataIndex: "averageScore",
              align: "center",
            },
            {
              title: "排名",
              dataIndex: "averageScoreRank",
              align: "center",
              customRender: (text, record) =>
                this.$parent.topData.isSchoolDisplayReportSchoolRank == 1
                  ? record.averageScoreRank
                  : "-",
            },
          ],
        },
        {
          title: "超均率（%）",
          dataIndex: "name",
          align: "center",
          children: [
            {
              title: "原始分",
              dataIndex: "overAverageRateOri",
              align: "center",
              customRender: (text) => (text ? text + "%" : ""),
            },
            {
              title: "排名",
              dataIndex: "overAverageRateOriRank",
              align: "center",
              customRender: (text, record) =>
                this.$parent.topData.isSchoolDisplayReportSchoolRank == 1
                  ? record.overAverageRateOriRank
                  : "-",
            },
            {
              title: "赋分",
              dataIndex: "overAverageRate",
              align: "center",
              customRender: (text) => (text ? text + "%" : ""),
            },
            {
              title: "排名",
              dataIndex: "overAverageRateRank",
              align: "center",
              customRender: (text, record) =>
                this.$parent.topData.isSchoolDisplayReportSchoolRank == 1
                  ? record.overAverageRateRank
                  : "-",
            },
          ],
        },
      ];
    },
    setRatios() {
      this.columns = [
        {
          title: "考试名称",
          dataIndex: "name",
          align: "center",
          customRender: (text, record, index) => {
            if (
              this.$parent.topData.isSchoolDisplayReportUnionAverageScore == 1
            ) {
              const obj = {
                children: text,
                attrs: {
                  rowSpan: index % 2 == 0 ? 2 : 0,
                },
              };
              return obj;
            }
            return text;
          },
        },
        {
          title: "学校",
          dataIndex: "schoolName",
          align: "center",
        },
        {
          title: "参统考生",
          dataIndex: "statTotal",
          align: "center",
        },
        ...this.ratios.map((item, index) => ({
          title: item.name,
          dataIndex: "ratios" + index,
          align: "center",
          children: [
            {
              slots: { title: "oriRate" },
              dataIndex: "ratios" + index + "ori",
              align: "center",
              customRender: (text, record) =>
                record.ratios[index].proportionOri !== null
                  ? record.ratios[index].proportionOri + "%"
                  : "",
            },
            {
              title: "排名",
              dataIndex: "ratios" + index + "orirank",
              align: "center",
              customRender: (text, record) =>
                record.ratios[index].rankOri || "-",
            },
            {
              title: "赋分",
              dataIndex: "ratios" + index + "ap",
              align: "center",
              customRender: (text, record) =>
                record.ratios[index].proportion !== null
                  ? record.ratios[index].proportion + "%"
                  : "",
            },
            {
              title: "排名",
              dataIndex: "ratios" + index + "aprank",
              align: "center",
              customRender: (text, record) => record.ratios[index].rank || "-",
            },
          ],
        })),
      ];
    },
    setTopSections() {
      this.columns = [
        {
          title: "考试名称",
          dataIndex: "name",
          align: "center",
          customRender: (text, record, index) => {
            if (
              this.$parent.topData.isSchoolDisplayReportUnionAverageScore == 1
            ) {
              const obj = {
                children: text,
                attrs: {
                  rowSpan: index % 2 == 0 ? 2 : 0,
                },
              };
              return obj;
            }
            return text;
          },
        },
        {
          title: "学校",
          dataIndex: "schoolName",
          align: "center",
        },
        {
          title: "参统考生",
          dataIndex: "statTotal",
          align: "center",
        },
        ...this.topSections.map((item, index) => ({
          title: item.name,
          dataIndex: "topSections" + index,
          align: "center",
          children: [
            {
              slots: { title: "oriRate" },
              dataIndex: "topSections" + index + "ori",
              align: "center",
              customRender: (text, record) =>
                record.topSections[index].proportionOri !== null
                  ? record.topSections[index].proportionOri + "%"
                  : "",
            },
            {
              title: "排名",
              dataIndex: "topSections" + index + "orirank",
              align: "center",
              customRender: (text, record) =>
                record.topSections[index].rankOri || "-",
            },
            {
              title: "赋分",
              dataIndex: "topSections" + index + "ap",
              align: "center",
              customRender: (text, record) =>
                record.topSections[index].proportion
                  ? record.topSections[index].proportion + "%"
                  : "",
            },
            {
              title: "排名",
              dataIndex: "topSections" + index + "aprank",
              align: "center",
              customRender: (text, record) =>
                record.topSections[index].rank || "-",
            },
          ],
        })),
      ];
    },
    setchart(field) {
      this.series = [];
      let schoolVOS = this.examArr[0].schoolVOS;
      const data = this.examArr.flatMap((item) => item.schoolVOS);
      this.series = schoolVOS.map((item) => {
        const a = data.filter((it) => item.schoolName === it.schoolName);
        const fin = a.map((i) => {
          const itemData = i[field].find((n) => n.name === this.params);
          if (itemData) {
            return itemData[
              this.dataSource == 1 ? "proportionOri" : "proportion"
            ];
          } else {
            return 0;
          }
        });
        return {
          name: item.schoolName,
          type: "line",
          label: {
            show: true,
            textStyle: {
              color: "inherit",
              fontSize: 9,
            },
            position: "top",
          },
          data: fin,
        };
      });
      this.chartKey = Math.random();
    },

    getExamList() {},
    subjectClick(item) {
      this.subjectId = item.id;
      if ([1, 2, 3, 4, 8].includes(this.subjectId)) {
        this.dataSource = "1";
      }
      if (this.subjectId == 0 && this.dataType == 3) {
        this.dataType = "1";
      }
      this.getData();
    },
    electiveClick(item) {
      this.electiveId = item.id;
      this.getData();
    },
    getClassType() {
      if (this.type == "2") {
        return "行政";
      } else {
        if (this.subjectId > 3 || this.electiveId !== null) {
          return "教学";
        }
        return "行政";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../new-exam.module.scss";

.tabs {
  //   margin-bottom: 18px;
}
.compare {
  background: #f3f8ff;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 10px;
  color: #3e4551;
  font-size: 14px;
  margin-bottom: 18px;
}
.btns {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  .btns-item {
    border-radius: 4px;
    border: 1px solid #d5d6db;
    box-sizing: border-box;
    padding: 6px 18px;
    color: #0a1119;
    font-size: 14px;
    cursor: pointer;
    margin-bottom: 18px;
    transition: all 0.2s ease-in-out;
    &:hover {
      border-color: #2474ed;
      background-color: #2474ed;
      color: #ffff;
    }
  }
  .btns-active {
    border-color: #2474ed;
    background-color: #2474ed;
    color: #ffff;
  }
  .btns-item + .btns-item {
    margin-left: 8px;
  }
}
.exam-filter {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 18px;
  .filter-item + .filter-item {
    margin-left: 8px;
  }
}
::v-deep .ant-select-selection--single {
  height: 35px;
  line-height: 35px;
}
.operate {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
}
.params {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 18px;
}
</style>
