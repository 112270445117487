<template>
  <div ref="chart" class="chart" :style="{ height: height }"></div>
</template>
<script>
import * as echarts from "echarts";
export default {
  props: {
    defineOptions: {
      default: () => {},
      type: Object,
    },
    height: {
      default: "380px",
      type: String,
    },
    series: {
      default: () => [],
      type: [Object, Array],
    },
    showAxis: {
      default: true,
      type: Boolean,
    },
    dataZoom: {
      default: 25,
      type: Number,
    },
  },
  data() {
    return {
      options: {
        grid: {
          left: "5%",
          bottom: "10%",
          right: "5%",
          top: "10%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        legend: {},
        color: ["#2474ED", "#5D7092", "#E8684A", "#F6BD16", "#5AD8A6"],
        xAxis: [
          {
            type: "category",
            data: ["语文", "数学", "英语", "物理", "历史", "地理"],
            axisPointer: {
              type: "shadow",
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              rotate: 25,
            },
          },
        ],
        yAxis: {
          type: "value",
        },
        series: [],
      },
    };
  },
  mounted() {
    this.initChart();
  },
  methods: {
    initChart() {
      let options = {};
      options = { ...this.options, ...this.defineOptions };
      if (!this.showAxis) {
        this.$delete(options, "yAxis");
        this.$delete(options, "xAxis");
      }

      options.series = this.series;
      let dataLength = 0;
      if (this.series.length) {
        if (this.series[0].data.length) {
          dataLength = this.series[0].data.length;
        }
        if (this.series.length && dataLength > this.dataZoom) {
          options.dataZoom = [
            //滚动条
            {
              show: dataLength > this.dataZoom,
              type: "slider",
              realtime: true,
              startValue: 0, // 重点
              // 重点-dataX.length表示x轴数据长度
              endValue: dataLength - (dataLength - this.dataZoom),
              xAxisIndex: [0],
              bottom: "3%",
              left: "center",
            },
          ];

          options.grid.bottom = this.series[0].data.length > 5 ? "18%" : "10%";
        }
      }

      var chart = echarts.init(this.$refs.chart);
      chart.setOption(options);
    },
  },
};
</script>
<style lang="scss" scoped>
.chart {
  width: 100%;
  height: 380px;
  margin-bottom: 18px;
}
</style>
