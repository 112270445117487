<template>
  <div ref="balance" v-lazyChart="{ fn: getCondition }" class="container">
    <div class="title">均衡性对比</div>
    <div class="subjectList">
      <div
        v-for="(item, index) in subjectList"
        :key="index"
        :class="['subject', subjectId === item.id ? 'active' : '']"
        @click="handleClick(item)"
      >
        {{ item.name }}
      </div>
    </div>
    <div v-if="subjectId !== 4" class="filter">
      选择{{ getClassType(subjectId) }}班级：
      <classNums
        :key="search.classNum"
        ref="classNums"
        placeholder="选择班级"
        :class-num-list="classNumList"
        @change="(e) => classNumChange(e, 'physisc')"
      />
    </div>
    <div v-if="subjectId !== 4" class="table physics">
      <div v-if="subjectId === 0" class="table-title">物理类</div>
      <exTable
        :key="tableKey"
        style="margin-bottom: 24px"
        :columns="physicsColumns"
        :data-source="physicsData"
      >
      </exTable>
    </div>
    <div v-if="[0, 4].includes(subjectId)" class="filter">
      选择{{ getClassType(subjectId) }}班级：
      <classNums
        :key="search.classNum2"
        ref="classNums"
        placeholder="选择班级"
        :class-num-list="classNumList2"
        @change="(e) => classNumChange(e, 'history')"
      />
    </div>
    <div v-if="[0, 4].includes(subjectId)" class="table historyData">
      <div v-if="subjectId === 0" class="table-title">历史类</div>
      <exTable
        :key="tableKey"
        :columns="historyColumns"
        :data-source="historyData"
      >
      </exTable>
    </div>
    <div class="tip">
      <div class="text">
        <div>
          此图按各班级总分平均分降序排列，蓝色代表班级指标值相对较大，红色代表班级指标值相对较小，横向查看同一班级不同学科情况，纵向比较同一学科不同班级情况，如出现色块跳跃则该班级该科值得关注。
        </div>
        <div class="wrapper">
          <div class="intent">注：</div>
          <div>
            <div>以上数据结果除赋分均分外均以原始分进行计算。</div>
            <div>以上数据均不包括不参与统计的考生成绩。</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import exTable from "../../components/exTable.vue";
import { gradientColors } from "./getGradientColors";
import { subjectbalancecompare } from "@/core/api/newExamAcademic/union/index";
import {
  getstatsubjectoption,
  getstatclassoption,
} from "@/core/api/academic/common";
import { getClassType } from "../../func.js";
import classNums from "../../components/classNums.vue";
export default {
  name: "ComparisonOfClassDisciplineBalance",
  components: {
    exTable,
    classNums,
  },
  data() {
    return {
      subjectList: [],
      physicsColumns: [],
      historyColumns: [],
      tableData: [],
      physicsData: [],
      historyData: [],
      phyColors: [],
      hisColors: [],
      subjectId: 0,
      search: {
        classNum: "1",
        classNum2: "1",
      },

      dataSubjects: [],
      dataSource: [],
      tableKey: Math.random(),
      classNumList: [],
      checkClassNum: [],
      classNumList2: [],
      checkClassNum2: [],
    };
  },
  created() {
    // this.getData();
  },
  methods: {
    getClassType: getClassType,
    async getCondition(loadingInstance, io) {
      const res = await getstatsubjectoption({
        id: this.$route.query.id,
        requestPage: 1,
        hasTotal: 1,
        viewSchoolId: this.$route.query.schoolId,
      });
      if (res.data.data.length) {
        this.subjectList = res.data.data;
        this.subjectId = res.data.data[0].id;
        this.getHisToryClassNumList();
        this.getPhysiscClassNumList();
      }
      this.closeLoading(loadingInstance, io);
    },
    async getHisToryClassNumList() {
      const classRes = await getstatclassoption({
        id: this.$route.query.id,
        subjectId: this.subjectId,
        getstatclassoption: 4,
        viewSchoolId: this.$route.query.schoolId,
        requestPage: 1,
      });
      this.classNumList2 = classRes.data.data.map((item) => item.name);
      this.checkClassNum2 = classRes.data.data.map((item) => item.name);
      this.search.classNum2 = classRes.data.data[0].name;

      this.getHistoryData();
    },
    async getPhysiscClassNumList() {
      const classRes = await getstatclassoption({
        id: this.$route.query.id,
        subjectId: this.subjectId,
        getstatclassoption: 8,
        requestPage: 1,
        viewSchoolId: this.$route.query.schoolId,
      });
      this.classNumList = classRes.data.data.map((item) => item.name);
      this.checkClassNum = classRes.data.data.map((item) => item.name);
      this.search.classNum = classRes.data.data[0].name;
      this.getPhysicsData();
    },

    closeLoading(loadingInstance, io) {
      if (loadingInstance && io) {
        this.$nextTick(() => {
          loadingInstance.close();
          io.unobserve(this.$refs.balance);
        });
      }
    },
    handleClick(item) {
      this.subjectId = item.id;
      this.getHisToryClassNumList();
      this.getPhysiscClassNumList();
    },
    getPhyHeatColors(num) {
      let num1, num2;
      if (num % 2 === 0) {
        num1 = num2 = num / 2;
      } else {
        num1 = Math.ceil(num);
        num2 = Math.floor(num);
      }
      var arr1 = gradientColors("#93BFEB", "#ffffff", num1 + 1);
      var arr2 = gradientColors("#ffffff", "#E86969", num2 + 1);
      arr1.pop();
      arr2.shift();
      this.phyColors = [...arr1, ...arr2];
    },
    getHisHeatColors(num) {
      let num1, num2;
      if (num % 2 === 0) {
        num1 = num2 = num / 2;
      } else {
        num1 = Math.ceil(num);
        num2 = Math.floor(num);
      }
      var arr1 = gradientColors("#93BFEB", "#ffffff", num1 + 1);
      var arr2 = gradientColors("#ffffff", "#E86969", num2 + 1);
      arr1.pop();
      arr2.shift();
      this.hisColors = [...arr1, ...arr2];
    },
    getColorts(overAverageTotal, rankTotal) {
      var arr1 = gradientColors("#93BFEB", "#ffffff", overAverageTotal + 1);
      var arr2 = gradientColors(
        "#ffffff",
        "#E86969",
        rankTotal - overAverageTotal + 1
      );
      arr1.pop();
      arr2.shift();
      return [...arr1, ...arr2];
    },
    classNumChange(val, type) {
      if (type === "history") {
        this.checkClassNum2 = val;
        this.getHistoryData();
      } else {
        this.checkClassNum = val;
        this.getPhysicsData();
      }
    },
    async getPhysicsData() {
      const res = await subjectbalancecompare({
        id: this.$route.query.id,
        classNums: this.checkClassNum.toString(),
        subjectId: this.subjectId,
        eleccateSubjectId: 8,
        isAllClass: 0,
        viewSchoolId: this.$route.query.schoolId,
      });
      this.tableData = res.data.data;
      this.physicsData = res.data.data;
      this.getPhyHeatColors(res.data.data.length);
      if (res.data.data) {
        this.setPhySubjectCols(res.data.data);
      }
    },
    async getHistoryData() {
      const res = await subjectbalancecompare({
        id: this.$route.query.id,
        classNums: this.checkClassNum2.toString(),
        subjectId: this.subjectId,
        eleccateSubjectId: 4,
        isAllClass: 0,
        viewSchoolId: this.$route.query.schoolId,
      });
      this.tableData = res.data.data;
      this.historyData = res.data.data;

      this.getHisHeatColors(res.data.data.length);
      if (res.data.data) {
        this.setHisSubjectCols(res.data.data);
      }
    },
    setPhySubjectCols(physicsData) {
      let beforehandCol = [
        {
          title: getClassType(this.subjectId) + "班",
          dataIndex: "classNum",
          align: "center",
          width: 85,
        },
      ];
      let physicsDataCol = [];
      if (physicsData.length) {
        let physics = physicsData[0].nonApSubjects.filter(
          (item) => item.subjectName !== "历史"
        );
        physicsDataCol = physics.flatMap((item, ind) => {
          let cols = [
            {
              title: item.subjectName + "均分",
              align: "center",
              width: 75,
              key: `averageScore-${item.subjectId}-${ind}`,
              customRender: (text, record) => {
                return <span>{record.nonApSubjects[ind].averageScore}</span>;
              },
              customCell: (record, index) =>
                this.setCellColor1(record, index, ind),
            },
            {
              title: !item.subjectId ? "班主任" : "任课教师",
              dataIndex: `averageScoreRank-${item.subjectId}-${ind}`,
              customHeaderCell: () => {
                return {
                  style: {
                    "text-overflow": "ellipsis",
                  },
                };
              },
              customCell: (record, index) =>
                this.setCellColor1(record, index, ind),
              align: "center",
              customRender: (text, record) => {
                let nonApSubjects = record.nonApSubjects.filter(
                  (it) => it.subjectName !== "历史"
                );
                return <span>{nonApSubjects[ind].teacherName || ""}</span>;
              },
              width: 120,
              key: `averageScoreRank-${item.subjectId}`,
            },
          ];
          if (this.$parent.topData.isSchoolDisplayReportClassRank == 1) {
            cols = cols.concat([
              {
                title: "校排名",
                align: "center",
                width: 85,
                key: `schoolRank-${item.subjectId}-${ind}`,
                customRender: (text, record) => {
                  return <span>{record.nonApSubjects[ind].rank}</span>;
                },
                customCell: (record, index) =>
                  this.setCellColor1(record, index, ind),
              },
              {
                title: "整体排名",
                align: "center",
                width: 75,
                key: `unionRank-${item.subjectId}-${ind}`,
                customRender: (text, record) => {
                  return <span>{record.nonApSubjects[ind].rankUnion}</span>;
                },
                customCell: (record, index) =>
                  this.setCellColor1(record, index, ind),
              },
            ]);
          }
          return cols;
        });
      }
      let totalCustomCell = (record) => {
        const { overAverageTotal, rankTotal, rank } = record;
        return {
          style: {
            "background-color": this.getColorts(overAverageTotal, rankTotal)[
              rank - 1
            ],
          },
        };
      };
      let col = [
        {
          title: "总分均分",
          dataIndex: "averageScoreOri",
          align: "center",
          width: 75,
          customCell: (record) => totalCustomCell(record),
        },
        {
          title: "赋分均分",
          dataIndex: "averageScore",
          align: "center",
          width: 75,
          customCell: (record) => totalCustomCell(record),
        },
        {
          title: "班主任",
          dataIndex: "teacherName/rank",
          align: "center",
          width: 120,
          customRender: (text, record) => `${record.teacherName || ""}`,
          customCell: (record) => totalCustomCell(record),
        },
        ...(this.$parent.topData.isSchoolDisplayReportClassRank == 1
          ? [
              {
                title: "校排名（大类）",
                dataIndex: "rank",
                align: "center",
                width: 100,
                customCell: (record) => totalCustomCell(record),
              },
              {
                title: "整体排名（大类）",
                dataIndex: "rankUnion",
                align: "center",
                width: 120,
                customCell: (record) => totalCustomCell(record),
              },
            ]
          : []),
      ];
      if (this.subjectId === 0) {
        beforehandCol.push({
          title: "选考组合",
          dataIndex: "eleccombName",
          align: "center",
          width: 100,
        });
        let phyChoosen = this.setPhyElectiveCols();
        this.physicsColumns = [
          ...beforehandCol,
          ...col,
          ...physicsDataCol,
          ...phyChoosen,
        ];
      } else {
        let totalCustomCell = (record) => {
          const { overAverageTotal, rankTotal, rank } = record;
          return {
            style: {
              "background-color": this.getColorts(overAverageTotal, rankTotal)[
                rank - 1
              ],
            },
          };
        };
        let subject = this.subjectList.find(
          (item) => item.id === this.subjectId
        );
        this.physicsColumns = [
          ...beforehandCol,
          {
            title: subject.name + "均分",
            dataIndex: "averageScore",
            align: "center",
            customCell: (record) => totalCustomCell(record),
          },
          {
            title: "任课教师",
            dataIndex: "teacherName",
            align: "center",
            customRender: (text, record) => `${record.teacherName || ""}`,
            customCell: (record) => totalCustomCell(record),
          },
          ...(this.$parent.topData.isSchoolDisplayReportClassRank == 1
            ? [
                {
                  title: "校排名",
                  dataIndex: "rank",
                  align: "center",
                  customRender: (text, record) => `${record.rank || ""}`,
                  customCell: (record) => totalCustomCell(record),
                },
                {
                  title: "整体排名",
                  dataIndex: "rankUnion",
                  align: "center",
                  customRender: (text, record) => `${record.rankUnion || ""}`,
                  customCell: (record) => totalCustomCell(record),
                },
              ]
            : []),
        ];
      }
      this.tableKey = Math.random();
    },
    setHisSubjectCols(historyData) {
      let beforehandCol = [
        {
          title: getClassType(this.subjectId) + "班",
          dataIndex: "classNum",
          align: "center",
          width: 85,
        },
      ];

      let historyDataCol = [];
      if (historyData.length) {
        let historys = historyData[0].nonApSubjects.filter(
          (it) => it.subjectName !== "物理"
        );
        historyDataCol = historys.flatMap((item, ind) => {
          let cols = [
            {
              title: item.subjectName + "均分",
              align: "center",
              width: 75,
              key: `averageScore-${item.subjectId}`,
              customRender: (text, record) => {
                let nonApSubjects = record.nonApSubjects.filter(
                  (it) => it.subjectName !== "物理"
                );
                return <span>{nonApSubjects[ind].averageScore}</span>;
              },
              customCell: (record, index) =>
                this.setCellColor1(record, index, ind),
            },
            {
              title: !item.subjectId ? "班主任" : "任课教师",
              dataIndex: "averageScoreRank",
              customHeaderCell: () => {
                return {
                  style: {
                    "text-overflow": "ellipsis",
                  },
                };
              },
              customCell: (record, index) =>
                this.setCellColor1(record, index, ind),
              align: "center",
              customRender: (text, record) => {
                return <span>{record.nonApSubjects[ind].teacherName}</span>;
              },
              width: 120,
              key: `averageScoreRank-${item.subjectId}`,
            },
          ];
          if (this.$parent.topData.isSchoolDisplayReportClassRank == 1) {
            cols = cols.concat([
              {
                title: "校排名",
                align: "center",
                width: 85,
                key: `schoolRank-${item.subjectId}`,
                customRender: (text, record) => {
                  let nonApSubjects = record.nonApSubjects.filter(
                    (it) => it.subjectName !== "物理"
                  );
                  return <span>{nonApSubjects[ind].rank}</span>;
                },
                customCell: (record, index) =>
                  this.setCellColor1(record, index, ind),
              },
              {
                title: "整体排名",
                align: "center",
                width: 75,
                key: `unionRank-${item.subjectId}`,
                customRender: (text, record) => {
                  let nonApSubjects = record.nonApSubjects.filter(
                    (it) => it.subjectName !== "物理"
                  );
                  return <span>{nonApSubjects[ind].rankUnion}</span>;
                },
                customCell: (record, index) =>
                  this.setCellColor1(record, index, ind),
              },
            ]);
          }
          return cols;
        });
      }
      let totalCustomCell = (record) => {
        const { overAverageTotal, rankTotal, rank } = record;
        return {
          style: {
            "background-color": this.getColorts(overAverageTotal, rankTotal)[
              rank - 1
            ],
          },
        };
      };
      let col = [
        {
          title: "总分均分",
          dataIndex: "averageScoreOri",
          align: "center",
          width: 75,
          customCell: (record) => totalCustomCell(record),
        },
        {
          title: "赋分均分",
          dataIndex: "averageScore",
          align: "center",
          width: 75,
          customCell: (record) => totalCustomCell(record),
        },
        {
          title: "班主任",
          dataIndex: "teacherName/rank",
          align: "center",
          width: 170,
          customRender: (text, record) => `${record.teacherName || ""}`,
          customCell: (record) => totalCustomCell(record),
        },
        ...(this.$parent.topData.isSchoolDisplayReportClassRank == 1
          ? [
              {
                title: "校排名（大类）",
                dataIndex: "rank",
                align: "center",
                width: 100,
                customRender: (text, record) => record.rank,
                customCell: (record) => totalCustomCell(record),
              },
              {
                title: "整体排名（大类）",
                dataIndex: "rankUnion",
                align: "center",
                width: 100,
                customRender: (text, record) => record.rankUnion,
                customCell: (record) => totalCustomCell(record),
              },
            ]
          : []),
      ];
      if (this.subjectId == 0) {
        beforehandCol.push({
          title: "选考组合",
          dataIndex: "eleccombName",
          align: "center",
          width: 100,
        });
        let hisChoosen = this.setHisElectiveCols();
        this.historyColumns = [
          ...beforehandCol,
          ...col,
          ...historyDataCol,
          ...hisChoosen,
        ];
      } else {
        let totalCustomCell = (record) => {
          const { overAverageTotal, rankTotal, rank } = record;
          return {
            style: {
              "background-color": this.getColorts(overAverageTotal, rankTotal)[
                rank - 1
              ],
            },
          };
        };
        let subject = this.subjectList.find(
          (item) => item.id === this.subjectId
        );
        // this.physicsColumns = [
        //   ...beforehandCol,
        //   {
        //     title: subject.name + "均分",
        //     dataIndex: "averageScore",
        //     align: "center",
        //     customCell: (record) => totalCustomCell(record),
        //   },
        //   {
        //     title: "任课教师",
        //     dataIndex: "teacherName/rank",
        //     align: "center",
        //     customRender: (text, record) => `${record.teacherName || ""}`,
        //     customCell: (record) => totalCustomCell(record),
        //   },
        // ];
        this.historyColumns = [
          ...beforehandCol,
          {
            title: subject.name + "均分",
            dataIndex: "averageScore",
            align: "center",
            customCell: (record) => totalCustomCell(record),
          },
          {
            title: "任课教师",
            dataIndex: "teacherName/rank",
            align: "center",
            customRender: (text, record) => `${record.teacherName || ""}`,
            customCell: (record) => totalCustomCell(record),
          },
          ...(this.$parent.topData.isSchoolDisplayReportClassRank == 1
            ? [
                {
                  title: "校排名",
                  dataIndex: "rank",
                  align: "center",
                  customRender: (text, record) => `${record.rank || ""}`,
                  customCell: (record) => totalCustomCell(record),
                },
                {
                  title: "整体排名",
                  dataIndex: "rankUnion",
                  align: "center",
                  customRender: (text, record) => `${record.rankUnion || ""}`,
                  customCell: (record) => totalCustomCell(record),
                },
              ]
            : []),
        ];
      }
      this.tableKey = Math.random();
    },
    setHisElectiveCols() {
      let customCell = (record, feild) => {
        let overAverageTotal = record[feild].overAverageTotal;
        let rank = record[feild].rank; // electiveSubject1 electiveSubject2 rank 必须存在值才能有颜色
        let rankTotal = record[feild].rankTotal;
        return {
          style: {
            "background-color": this.getColorts(overAverageTotal, rankTotal)[
              rank - 1
            ],
          },
        };
      };
      return [
        {
          dataIndex: "electiveSubject1",
          width: 105,
          align: "center",
          customCell: (record) => customCell(record, "electiveSubject1"),
          customRender: (text, record) => (
            <span>
              {record.electiveSubject1.subjectName || "-"}/
              {record.electiveSubject1.averageScore || "-"}
            </span>
          ),
          scopedSlots: {
            title: "title1",
          },
        },
        {
          title: "任课教师 ",
          dataIndex: "electiveSubject1-teacher",
          align: "center",
          width: 120,
          customCell: (record) => customCell(record, "electiveSubject1"),
          customRender: (text, record) => {
            return <span>{record.electiveSubject1.teacherName}</span>;
          },
        },
        ...(this.$parent.topData.isSchoolDisplayReportClassRank == 1
          ? [
              {
                dataIndex: "electiveSubject1-schoolRank",
                width: 85,
                align: "center",
                customCell: (record) => customCell(record, "electiveSubject2"),
                customRender: (text, record) => (
                  <span>{record.electiveSubject1.rank}</span>
                ),
                title: "校排名",
              },
              {
                dataIndex: "electiveSubject1-unionRank",
                width: 100,
                align: "center",
                customCell: (record) => customCell(record, "electiveSubject2"),
                customRender: (text, record) => (
                  <span>{record.electiveSubject1.rankUnion}</span>
                ),
                title: "整体排名",
              },
            ]
          : []),
        {
          dataIndex: "electiveSubject2",
          width: 105,
          align: "center",
          customCell: (record) => customCell(record, "electiveSubject2"),
          customRender: (text, record) => (
            <span>
              {record.electiveSubject2.subjectName || "-"}/
              {record.electiveSubject2.averageScore || "-"}
            </span>
          ),
          scopedSlots: {
            title: "title2",
          },
        },
        {
          title: "任课教师",
          dataIndex: "electiveSubject2-teacher",
          width: 120,
          align: "center",
          customCell: (record) => customCell(record, "electiveSubject2"),
          customRender: (text, record) => {
            return <span>{record.electiveSubject2.teacherName}</span>;
          },
        },
        ...(this.$parent.topData.isSchoolDisplayReportClassRank == 1
          ? [
              {
                dataIndex: "electiveSubject2-schoolRank",
                width: 85,
                align: "center",
                customCell: (record) => customCell(record, "electiveSubject2"),
                customRender: (text, record) => (
                  <span>{record.electiveSubject2.rank}</span>
                ),
                title: "校排名",
              },
              {
                dataIndex: "electiveSubject2-unionRank",
                width: 100,
                align: "center",
                customCell: (record) => customCell(record, "electiveSubject2"),
                customRender: (text, record) => (
                  <span>{record.electiveSubject2.rankUnion}</span>
                ),
                title: "整体排名",
              },
            ]
          : []),
      ];
    },
    setPhyElectiveCols() {
      let customCell = (record, feild) => {
        let overAverageTotal = record[feild].overAverageTotal;
        let rank = record[feild].rank;
        let rankTotal = record[feild].rankTotal;
        return {
          style: {
            "background-color": this.getColorts(overAverageTotal, rankTotal)[
              rank - 1
            ],
          },
        };
      };
      return [
        {
          dataIndex: "electiveSubject1",
          width: 105,
          align: "center",
          customCell: (record) => customCell(record, "electiveSubject1"),
          customRender: (text, record) => (
            <span>
              {record.electiveSubject1.subjectName || "-"}/
              {record.electiveSubject1.averageScore || "-"}
            </span>
          ),
          scopedSlots: {
            title: "title1",
          },
        },
        {
          title: "任课教师",
          dataIndex: "electiveSubject1-teacher",
          align: "center",
          width: 120,
          customCell: (record) => customCell(record, "electiveSubject1"),
          customRender: (text, record) => {
            return <span>{record.electiveSubject1.teacherName}</span>;
          },
        },
        ...(this.$parent.topData.isSchoolDisplayReportClassRank == 1
          ? [
              {
                dataIndex: "electiveSubject1-schoolRank",
                width: 85,
                align: "center",
                customCell: (record) => customCell(record, "electiveSubject2"),
                customRender: (text, record) => (
                  <span>{record.electiveSubject1.rank}</span>
                ),
                title: "校排名",
              },
              {
                dataIndex: "electiveSubject1-unionRank",
                width: 100,
                align: "center",
                customCell: (record) => customCell(record, "electiveSubject2"),
                customRender: (text, record) => (
                  <span>{record.electiveSubject1.rankUnion}</span>
                ),
                title: "整体排名",
              },
            ]
          : []),
        {
          dataIndex: "electiveSubject2",
          width: 105,
          align: "center",
          customCell: (record) => customCell(record, "electiveSubject2"),
          customRender: (text, record) => (
            <span style="white-space: nowrap">
              {record.electiveSubject2.subjectName || "-"}/
              {record.electiveSubject2.averageScore || "-"}
            </span>
          ),
          scopedSlots: {
            title: "title2",
          },
        },
        {
          title: "任课教师",
          dataIndex: "electiveSubject2-teacher",
          width: 120,
          align: "center",
          customCell: (record) => customCell(record, "electiveSubject2"),

          customRender: (text, record) => {
            return <span>{record.electiveSubject2.teacherName}</span>;
          },
        },
        ...(this.$parent.topData.isSchoolDisplayReportClassRank == 1
          ? [
              {
                dataIndex: "electiveSubject2-schoolRank",
                width: 85,
                align: "center",
                customCell: (record) => customCell(record, "electiveSubject2"),
                customRender: (text, record) => (
                  <span>{record.electiveSubject2.rank}</span>
                ),
                title: "校排名",
              },
              {
                dataIndex: "electiveSubject2-unionRank",
                width: 100,
                align: "center",
                customCell: (record) => customCell(record, "electiveSubject2"),
                customRender: (text, record) => (
                  <span>{record.electiveSubject2.rankUnion}</span>
                ),
                title: "整体排名",
              },
            ]
          : []),
      ];
    },
    setCellColor1(record, index, ind) {
      let overAverageTotal = record.nonApSubjects[ind].overAverageTotal;
      let rank = record.nonApSubjects[ind].rank;
      let rankTotal = record.nonApSubjects[ind].rankTotal;
      return {
        style: {
          "background-color": this.getColorts(overAverageTotal, rankTotal)[
            rank - 1
          ],
        },
      };
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../new-exam.module.scss";
.subjectList {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  font-size: 14px;
  color: #0a1119;
  .subject {
    margin-bottom: 18px;
    border-radius: 4px;
    border: 1px solid #d5d6db;
    box-sizing: border-box;
    padding: 6px 18px;
    cursor: pointer;
    transition: 0.2s all ease-in-out;
  }
  .subject + .subject {
    margin-left: 8px;
  }
  .active {
    color: #fff;
    border-color: #2474ed;
    background-color: #2474ed;
  }
}
.table {
  &-title {
    text-align: center;
    height: 36px;
    background: #f3f8ff;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    color: #0a1119;
    margin-bottom: 8px;
    line-height: 36px;
  }
}
.filter {
  margin-bottom: 18px;
  display: flex;
  justify-content: flex-start;
}
</style>
