<template>
  <div ref="subject" v-lazyChart="{ fn: getCondition }" class="container">
    <div class="title">学科竞争力</div>
    <div v-if="subjectList.length" class="filter-box">
      <div class="subjectList">
        <div
          v-for="(item, index) in subjectList"
          :key="index"
          :class="['subject', search.subjectId === item.id ? 'active' : '']"
          @click="handleClick(item)"
        >
          {{ item.name === "总分" ? "全部" : item.name }}
        </div>
      </div>
    </div>
    <div class="operate">
      <div class="dataSource">
        <template v-if="[0, 5, 6, 7, 9].includes(search.subjectId)">
          数据源：
          <a-radio-group v-model="search.dataSource" @change="dataSourceChange">
            <a-radio :value="1"> 原始分</a-radio>
            <a-radio
              v-if="![1, 2, 3, 4, 8].includes(search.subjectId)"
              :value="2"
            >
              赋分
            </a-radio>
          </a-radio-group>
        </template>
      </div>
      <a-radio-group
        v-if="subjectList.length"
        v-model="search.category"
        button-style="solid"
        @change="categoryChange"
      >
        <a-radio-button :value="1"> 平均分 </a-radio-button>
        <a-radio-button :value="2"> 比率 </a-radio-button>
        <a-radio-button
          v-if="search.subjectId != 0 && topSections.length"
          :value="3"
        >
          前N名
        </a-radio-button>
        <a-radio-button v-if="search.subjectId != 0" :value="4">
          其他指标
        </a-radio-button>
      </a-radio-group>
    </div>
    <exTable
      :key="tableKey"
      :columns="columns"
      :data-source="tableData"
      :show-last-record="false"
      style="margin-bottom: 24px"
      :meger-list="[]"
      :col-span-num="0"
    />
    <div class="select">
      <a-select
        v-if="[2, 4, 3].includes(search.category)"
        v-model="selectValue"
        style="width: 150px"
        @change="selectChange"
      >
        <a-select-option
          v-for="(item, index) in selectOption"
          :key="index"
          :value="item.value"
        >
          {{ item.label }}
        </a-select-option>
      </a-select>
    </div>
    <ChartContainer
      v-if="defineOptions"
      :key="tableKey + 'chart'"
      :define-options="defineOptions"
      :series="series"
    />
    <no-data v-else message="暂无数据"></no-data>
    <div class="tip">
      <div>注：</div>
      <div>
        当前结果均以{{ search.dataSource == 1 ? "原始分" : "赋分" }}进行运算
      </div>
    </div>
  </div>
</template>

<script>
import { getstatsubjectoption } from "@/core/api/academic/common";
import exTable from "../../components/exTable.vue";
import ChartContainer from "../components/ChartContainer.vue";
import { subjectcompetitive } from "@/core/api/newExamAcademic/union/index";
export default {
  name: "",
  components: {
    exTable,
    ChartContainer,
  },
  data() {
    return {
      columns: [],
      tableData: [],
      subjectList: [],
      selectValue: null,
      defineOptions: null,
      series: [],
      search: {
        subjectId: "",
        dataSource: 2,
        category: 1,
      },
      tableKey: Math.random(),
      selectOption: [],
      nemuCols: [
        {
          title: "学科",
          dataIndex: "subjectName",
          key: "subjectName",
          fixed: true,
          width: 85,
          align: "center",
          customRender: (text, record, index) => {
            const obj = {
              children: record.subjectName,
              attrs: { rowSpan: 1 },
            };
            obj.attrs.rowSpan = this.renderCells(
              record.subjectName,
              this.tableData,
              "subjectName",
              index
            );
            return obj;
          },
        },
        {
          title: "学校",
          dataIndex: "schoolName",
          key: "schoolName",
          width: 125,
          fixed: true,
          align: "center",
        },
        {
          title: "统计人数",
          dataIndex: "statTotal",
          key: "statTotal",
          width: 100,
          fixed: true,
          align: "center",
        },
      ],
      ratios: [],
      allData: [],
    };
  },
  created() {},
  methods: {
    selectChange() {
      if (this.search.category == 2) {
        this.setRatios();
      } else if (this.search.category == 3) {
        this.setTopSection();
      } else if (this.search.category == 4) {
        this.setOther();
      }
    },
    renderCells(text, data, key, index) {
      if (data.length < 1) {
        return 1;
      }
      if (text === "" || text === null) {
        data[index].rowNum = 1;
        return 1;
      }
      // 上一行该列数据是否一样
      if (index !== 0 && text === data[index - 1][key]) {
        data[index].rowNum = 0;
        return 0;
      }
      let rowSpan = 1;
      // 判断下一行是否相等
      for (let i = index + 1; i < data.length; i++) {
        if (text !== data[i][key]) {
          break;
        }
        rowSpan++;
      }
      data[index].rowNum = rowSpan;
      return rowSpan;
    },
    categoryChange() {
      if (this.search.category == 1) {
        this.setAvg();
      } else if (this.search.category == 2) {
        this.selectOption = this.ratios.map((item) => ({
          label: item.name,
          value: item.name,
        }));
        if (this.ratios.length) {
          this.selectValue = this.ratios[0].name;
        }
        this.setRatios();
      } else if (this.search.category == 3) {
        this.selectOption = this.topSections.map((item) => ({
          label: item.name,
          value: item.name,
        }));
        if (this.topSections.length) {
          this.selectValue = this.topSections[0].name;
        }
        this.setTopSection();
      } else if (this.search.category == 4) {
        this.selectOption = [
          {
            label: "标准差",
            value: "standardDeviation",
          },
          {
            label: "最高分",
            value: "highestScore",
          },
          {
            label: "最低分",
            value: "lowestScore",
          },
          {
            label: "超均率",
            value: "overAverageRate",
          },
        ];
        this.selectValue = "standardDeviation";
        this.setOther();
      }
    },
    dataSourceChange() {
      if (this.search.subjectId == 0 && this.search.dataSource == 2) {
        this.tableData = this.allData.filter(
          (item) => ![1, 2, 3, 4, 8].includes(item.subjectId)
        );
      } else {
        this.tableData = [...this.allData];
      }
      if (this.search.category == 1) {
        this.setAvg();
      } else if (this.search.category == 2) {
        this.setRatios();
      } else if (this.search.category == 3) {
        this.setTopSection();
      } else if (this.search.category == 4) {
        this.setOther();
      }
    },
    handleClick(item) {
      if ([1, 2, 3, 4, 8].includes(item.id)) {
        this.search.dataSource = 1;
      } else {
        this.search.dataSource = 2;
      }
      if (item.id == 0 && [3, 4].includes(this.search.category)) {
        this.search.category = 1;
      }
      this.search.subjectId = item.id;
      this.getData();
    },
    async getCondition(loadingInstance, io) {
      const res = await getstatsubjectoption({
        id: this.$route.query.id,
        hasTotal: 1,
        viewSchoolId: this.$route.query.schoolId,
      });
      if (res.data.data.length) {
        this.subjectList = res.data.data;
        this.search.subjectId = res.data.data[0].id;
        this.getData(loadingInstance, io);
      } else {
        this.closeLoading(loadingInstance, io);
      }
    },
    async getData(loadingInstance, io) {
      try {
        const res = await subjectcompetitive({
          id: this.$route.query.id,
          subjectId: this.search.subjectId,
          viewSchoolId: this.$route.query.schoolId,
        });
        this.allData = res.data.data;
        if (this.search.subjectId == 0 && this.search.dataSource == 2) {
          this.tableData = res.data.data.filter(
            (item) => ![1, 2, 3, 4, 8].includes(item.subjectId)
          );
        } else {
          this.tableData = res.data.data;
        }
        if (res.data.data.length) {
          this.ratios = res.data.data[0].ratios;
          this.topSections = res.data.data[0].topSections || [];
        }
        this.categoryChange();
        this.closeLoading(loadingInstance, io);
      } catch {
        this.closeLoading(loadingInstance, io);
      }
    },
    closeLoading(loadingInstance, io) {
      if (loadingInstance && io) {
        this.$nextTick(() => {
          loadingInstance.close();
          io.unobserve(this.$refs.subject);
        });
      }
    },
    setAvg() {
      let cols = [...this.nemuCols];
      if (this.search.subjectId > 0) {
        cols = this.nemuCols.filter((item) => item.title != "学科");
      }

      this.columns = [
        ...cols,
        {
          title: "原始分",
          dataIndex: "ori",
          align: "center",
          children: [
            {
              title: "平均分",
              dataIndex: "averageScoreOri",
              align: "center",
            },
            ...(this.$parent.topData.isSchoolDisplayReportSchoolRank == 1
              ? [
                  {
                    title: "排名",
                    dataIndex: "averageScoreOriRank",
                    align: "center",
                    customRender: (text) => text || "-",
                  },
                ]
              : []),
          ],
        },
        {
          title: "赋分",
          dataIndex: "ap",
          align: "center",
          children: [
            {
              title: "平均分",
              dataIndex: "averageScore",
              align: "center",
            },
            ...(this.$parent.topData.isSchoolDisplayReportSchoolRank == 1
              ? [
                  {
                    title: "排名",
                    dataIndex: "averageScoreRank",
                    align: "center",
                    customRender: (text) => text || "-",
                  },
                ]
              : []),
          ],
        },
        {
          dataIndex: this.search.dataSource == 1 ? "medianOri" : "median",
          align: "center",
          title: "中位数",
        },
        {
          dataIndex: "scoreRateOri",
          align: "center",
          title: "得分率（%）",
          children: [
            {
              title: "数值",
              dataIndex:
                this.search.dataSource == 1 ? "scoreRateOri" : "scoreRate",
              align: "center",
            },
            ...(this.$parent.topData.isSchoolDisplayReportSchoolRank == 1
              ? [
                  {
                    title: "排名",
                    dataIndex:
                      this.search.dataSource == 1
                        ? "scoreRateOriRank"
                        : "scoreRateRank",
                    align: "center",
                    customRender: (text) => text || "-",
                  },
                ]
              : []),
          ],
        },
        {
          dataIndex:
            this.search.dataSource == 1 ? "highestScoreOri" : "highestScore",
          align: "center",
          title: "最高分",
        },
        {
          dataIndex:
            this.search.dataSource == 1 ? "lowestScoreOri" : "lowestScore",
          align: "center",
          title: "最低分",
        },
      ];
      let xAxis = [];
      if (this.search.subjectId == 0) {
        xAxis = [...new Set(this.tableData.map((item) => item.subjectName))];
        const upSchool = xAxis.map(
          (item) =>
            this.tableData.find(
              (it) => it.schoolName == "上位学校" && it.subjectName == item
            ) || {}
        );
        const downSchool = xAxis.map(
          (item) =>
            this.tableData.find(
              (it) => it.schoolName == "下位学校" && it.subjectName == item
            ) || {}
        );
        const highSchool = xAxis.map(
          (item) =>
            this.tableData.find(
              (it) => it.schoolName == "整体高位学校" && it.subjectName == item
            ) || {}
        );
        this.series = [
          {
            type: "bar",
            name: "本校",
            barMaxWidth: 40,
            label: {
              position: "top",
              show: true,
              color: "inherit",
              fontSize: 9,
            },
            data: this.tableData
              .filter((item) => item.schoolName == "本校")
              .map((it) =>
                this.search.dataSource == 1
                  ? it.averageScoreOri
                  : it.averageScore
              ),
          },

          {
            type: "line",
            name: "上位学校",
            smooth: true,
            data: upSchool.map((item) =>
              this.search.dataSource == 1
                ? item.averageScoreOri
                : item.averageScore
            ),
          },
          {
            type: "line",
            name: "下位学校",
            smooth: true,
            data: downSchool.map((item) =>
              this.search.dataSource == 1
                ? item.averageScoreOri
                : item.averageScore
            ),
          },
          {
            type: "line",
            name: "整体高位学校",
            smooth: true,
            data: highSchool.map((item) =>
              this.search.dataSource == 1
                ? item.averageScoreOri
                : item.averageScore
            ),
          },
        ];
        if (this.$parent.topData.isSchoolDisplayReportUnionAverageScore == 1) {
          this.series.push({
            type: "line",
            name: "整体",
            smooth: true,
            data: this.tableData
              .filter((item) => item.schoolName == "整体")
              .map((it) =>
                this.search.dataSource == 1
                  ? it.averageScoreOri
                  : it.averageScore
              ),
          });
        }
      } else {
        const notTotal = this.tableData.filter(
          (item) => item.schoolName != "整体"
        );
        xAxis = notTotal.map((item) => item.schoolName);
        const totalData = this.tableData.find(
          (item) => item.schoolName == "整体"
        );
        this.series = [
          {
            type: "bar",
            name: "学校",
            barMaxWidth: 40,
            label: {
              position: "top",
              show: true,
              color: "inherit",
              fontSize: 9,
            },
            data: notTotal.map((it) =>
              this.search.dataSource == 1 ? it.averageScoreOri : it.averageScore
            ),
          },
        ];
        if (this.$parent.topData.isSchoolDisplayReportUnionAverageScore == 1) {
          this.series.push({
            type: "line",
            name: "整体",
            smooth: true,
            color: "#E8684A",
            data: notTotal.map(() =>
              this.search.dataSource == 1
                ? totalData.averageScoreOri
                : totalData.averageScore
            ),
          });
        }
      }
      this.defineOptions = {
        color: ["#5B8FF9", "#5AD8A6", "#5D7092", "#F6BD16", "#E8684A"],
        grid: {
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: xAxis,
            axisPointer: {
              type: "shadow",
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "rgba(0, 0, 0, 0.45)",
              },
            },
            axisTick: {
              show: false,
            },
          },
        ],
      };
      this.tableKey = Math.random();
    },
    setRatios() {
      let cols = [...this.nemuCols];
      if (this.search.subjectId > 0) {
        cols = this.nemuCols.filter((item) => item.title != "科目");
      }
      this.columns = [
        ...cols,
        ...this.ratios.map((item, index) => {
          return {
            title: item.name,
            align: "center",
            dataIdnex: "ratios" + index,
            children: [
              {
                title: "人数",
                align: "center",
                dataIndex: "ratios" + index + "total",
                customRender: (text, record) => {
                  return record.ratios[index][
                    this.search.dataSource == 1 ? "totalOri" : "total"
                  ];
                },
              },
              {
                title: "占比（%）",
                align: "center",
                dataIndex: "ratios" + index + "proportion",
                customRender: (text, record) => {
                  return record.ratios[index][
                    this.search.dataSource == 1 ? "proportionOri" : "proportion"
                  ];
                },
              },
              ...(this.$parent.topData.isSchoolDisplayReportSchoolRank == 1
                ? [
                    {
                      title: "排名",
                      align: "center",
                      dataIndex: "ratios" + index + "rank",
                      customRender: (text, record) => {
                        return record.ratios[index][
                          this.search.dataSource == 1 ? "rankOri" : "rank"
                        ];
                      },
                    },
                  ]
                : []),
            ],
            // customRender:(text,record) => {
            //   let ratio = record.ratios[index];
            //   return
            // }
          };
        }),
      ];
      let xAxis = [];
      if (this.search.subjectId == 0) {
        xAxis = [...new Set(this.tableData.map((item) => item.subjectName))];
        const upSchool = xAxis.map(
          (item) =>
            this.tableData.find(
              (it) => it.schoolName == "上位学校" && it.subjectName == item
            ) || {}
        );
        const downSchool = xAxis.map(
          (item) =>
            this.tableData.find(
              (it) => it.schoolName == "下位学校" && it.subjectName == item
            ) || {}
        );
        const highSchool = xAxis.map(
          (item) =>
            this.tableData.find(
              (it) => it.schoolName == "整体高位学校" && it.subjectName == item
            ) || {}
        );
        this.series = [
          {
            type: "bar",
            name: "学校 ",
            barMaxWidth: 40,
            label: {
              position: "top",
              show: true,
              color: "inherit",
              fontSize: 9,
            },
            data: this.tableData
              .filter((item) => item.schoolName == "本校")
              .map((it) => {
                let ratio = it.ratios.find((i) => i.name == this.selectValue);
                return this.search.dataSource == 1
                  ? ratio.totalOri
                  : ratio.total;
              }),
          },

          {
            type: "line",
            name: "上位学校",
            smooth: true,
            data: upSchool.map((it) => {
              if (it.ratios) {
                let ratio = it.ratios.find((i) => i.name == this.selectValue);
                return this.search.dataSource == 1
                  ? ratio.totalOri
                  : ratio.total;
              }
              return null;
            }),
          },
          {
            type: "line",
            name: "下位学校",
            smooth: true,
            data: downSchool.map((it) => {
              if (it.ratios) {
                let ratio = it.ratios.find((i) => i.name == this.selectValue);
                return this.search.dataSource == 1
                  ? ratio.totalOri
                  : ratio.total;
              }
              return null;
            }),
          },
          {
            type: "line",
            name: "整体高位学校",
            smooth: true,
            data: highSchool.map((it) => {
              if (it.ratios) {
                let ratio = it.ratios.find((i) => i.name == this.selectValue);
                return this.search.dataSource == 1
                  ? ratio.totalOri
                  : ratio.total;
              }
              return null;
            }),
          },
        ];
        if (this.$parent.topData.isSchoolDisplayReportUnionAverageScore == 1) {
          this.series.push({
            type: "line",
            name: "整体",
            smooth: true,
            data: this.tableData
              .filter((item) => item.schoolName == "整体")
              .map((it) => {
                let ratio = it.ratios.find((i) => i.name == this.selectValue);

                return this.search.dataSource == 1
                  ? ratio.totalOri
                  : ratio.total;
              }),
          });
        }
      } else {
        const notTotal = this.tableData.filter(
          (item) => item.schoolName != "整体"
        );
        xAxis = notTotal.map((item) => item.schoolName);
        const totalData = this.tableData.find(
          (item) => item.schoolName == "整体"
        );

        this.series = [
          {
            type: "bar",
            name: "学校",
            barMaxWidth: 40,
            label: {
              position: "top",
              show: true,
              color: "inherit",
              fontSize: 9,
            },
            data: notTotal.map((it) => {
              let ratio = it.ratios.find((i) => i.name == this.selectValue);
              return this.search.dataSource == 1 ? ratio.totalOri : ratio.total;
            }),
          },
          {
            type: "line",
            name: "整体",
            smooth: true,
            color: "#E8684A",
            data: notTotal.map(() => {
              let ratio = totalData.ratios.find(
                (i) => i.name == this.selectValue
              );
              return this.search.dataSource == 1 ? ratio.totalOri : ratio.total;
            }),
          },
        ];
      }
      this.defineOptions = {
        grid: {
          containLabel: true,
        },
        color: ["#5B8FF9", "#5AD8A6", "#5D7092", "#F6BD16", "#E8684A"],
        xAxis: [
          {
            type: "category",
            data: xAxis,
            axisPointer: {
              type: "shadow",
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "rgba(0, 0, 0, 0.45)",
              },
            },
            axisTick: {
              show: false,
            },
          },
        ],
      };
      this.tableKey = Math.random();
    },
    setTopSection() {
      let cols = [...this.nemuCols];
      if (this.search.subjectId > 0) {
        cols = this.nemuCols.filter((item) => item.title != "科目");
      }
      this.columns = [
        ...cols,
        ...this.topSections.map((item, index) => {
          return {
            title: item.name,
            align: "center",
            dataIdnex: "topSections" + index,
            children: [
              {
                title: "上线人数",
                align: "center",
                dataIndex: "topSections" + index + "total",
                customRender: (text, record) => {
                  return record.topSections[index][
                    this.search.dataSource == 1 ? "totalOri" : "total"
                  ];
                },
              },
              {
                title: "占比（%）",
                align: "center",
                dataIndex: "topSections" + index + "proportion",
                customRender: (text, record) => {
                  return record.topSections[index][
                    this.search.dataSource == 1 ? "proportionOri" : "proportion"
                  ];
                },
              },
              ...(this.$parent.topData.isSchoolDisplayReportSchoolRank == 1
                ? [
                    {
                      title: "排名",
                      align: "center",
                      dataIndex: "topSections" + index + "rank",
                      customRender: (text, record) => {
                        return record.topSections[index][
                          this.search.dataSource == 1 ? "rankOri" : "rank"
                        ];
                      },
                    },
                  ]
                : []),
            ],
          };
        }),
      ];
      const notTotal = this.tableData.filter(
        (item) => item.schoolName != "整体"
      );
      let xAxis = [];
      xAxis = notTotal.map((item) => item.schoolName);
      const totalData = this.tableData.find(
        (item) => item.schoolName == "整体"
      );
      this.series = [
        {
          type: "bar",
          name: "学校",
          barMaxWidth: 40,
          label: {
            position: "top",
            show: true,
            color: "inherit",
            fontSize: 9,
          },
          data: notTotal.map((it) => {
            let item = it.topSections.find((i) => i.name == this.selectValue);
            return this.search.dataSource == 1 ? item.totalOri : item.total;
          }),
        },
      ];
      if (this.$parent.topData.isSchoolDisplayReportUnionAverageScore == 1) {
        this.series.push({
          type: "line",
          name: "整体",
          smooth: true,
          color: "#E8684A",
          data: notTotal.map(() => {
            let item = totalData.topSections.find(
              (i) => i.name == this.selectValue
            );
            return this.search.dataSource == 1 ? item.totalOri : item.total;
          }),
        });
      }
      this.defineOptions = {
        grid: {
          containLabel: true,
        },
        color: ["#5B8FF9", "#5AD8A6", "#5D7092", "#F6BD16", "#E8684A"],
        xAxis: [
          {
            type: "category",
            data: xAxis,
            axisPointer: {
              type: "shadow",
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "rgba(0, 0, 0, 0.45)",
              },
            },
            axisTick: {
              show: false,
            },
          },
        ],
      };
      this.tableKey = Math.random();
    },
    setOther() {
      this.columns = [
        {
          title: "学校",
          dataIndex: "schoolName",
          key: "schoolName",
          align: "center",
        },
        {
          title: "统计人数",
          dataIndex: "statTotal",
          key: "statTotal",
          align: "center",
        },
        {
          title: "最高分",
          dataIndex:
            this.search.dataSource == 1 ? "highestScoreOri" : "highestScore",
          align: "center",
        },
        {
          title: "最低分",
          dataIndex:
            this.search.dataSource == 1 ? "lowestScoreOri" : "lowestScore",
          align: "center",
        },
        {
          title: "全距",
          dataIndex:
            this.search.dataSource == 1 ? "rangeScoreOri" : "rangeScore",
          align: "center",
        },
        {
          title: "中位数",
          dataIndex: this.search.dataSource == 1 ? "medianOri" : "median",
          align: "center",
        },
        {
          title: "离均差",
          dataIndex:
            this.search.dataSource == 1
              ? "deviationFromMeanOri"
              : "deviationFromMean",
          align: "center",
        },
        {
          title: "整体超均率",
          dataIndex: "overAverageRate",
          align: "center",
          customRender: (text, record) =>
            this.search.dataSource == 1
              ? record.overAverageRateOri
              : record.overAverageRate,
        },
        {
          title: "标准差",
          dataIndex:
            this.search.dataSource == 1
              ? "standardDeviationOri"
              : "standardDeviation",
          align: "center",
        },
        {
          title: "差异系数",
          dataIndex:
            this.search.dataSource == 1
              ? "coefficientOfVariationOri"
              : "coefficientOfVariation",
          align: "center",
        },
      ];
      const notTotal = this.tableData.filter(
        (item) => item.schoolName != "整体"
      );
      const xAxis = notTotal.map((item) => item.schoolName);
      const totalData = this.tableData.find(
        (item) => item.schoolName == "整体"
      );

      this.series = [
        {
          type: "bar",
          name: "学校",
          barMaxWidth: 40,
          label: {
            position: "top",
            show: true,
            color: "inherit",
            fontSize: 9,
          },
          data: notTotal.map(
            (it) =>
              it[
                this.search.dataSource == 1
                  ? this.selectValue + "Ori"
                  : this.selectValue
              ]
          ),
        },
      ];
      if (this.$parent.topData.isSchoolDisplayReportUnionAverageScore == 1) {
        this.series.push({
          type: "line",
          name: "整体",
          smooth: true,
          color: "#E8684A",
          data: notTotal.map(
            () =>
              totalData[
                this.search.dataSource == 1
                  ? this.selectValue + "Ori"
                  : this.selectValue
              ]
          ),
        });
      }
      this.defineOptions = {
        grid: {
          containLabel: true,
        },
        color: ["#5B8FF9", "#5AD8A6", "#5D7092", "#F6BD16", "#E8684A"],
        xAxis: [
          {
            type: "category",
            data: xAxis,
            axisPointer: {
              type: "shadow",
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "rgba(0, 0, 0, 0.45)",
              },
            },
            axisTick: {
              show: false,
            },
          },
        ],
      };
      this.tableKey = Math.random();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../new-exam.module.scss";
.filter-box {
  box-sizing: border-box;
  padding: 16px;
  margin-bottom: 18px;
  border-radius: 8px;
  border: 1px solid #d5d6db;
  .subjectList {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    color: #0a1119;
    font-size: 14px;
    .label {
      margin-right: 8px;
      margin-bottom: 18px;
    }
    .subject {
      border-radius: 4px;
      border: 1px solid #d5d6db;
      box-sizing: border-box;
      padding: 6px 18px;
      cursor: pointer;
      background: #fff;
      //   margin-bottom: 18px;
      transition: all 0.2s ease-in-out;
    }
    .active {
      color: #fff;
      background: #2474ed;
      border-color: #2474ed;
    }
    .subject + .subject {
      margin-left: 8px;
    }
  }
  .resEleccomb {
    margin-left: 21px;
  }
}
.operate {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}
.select {
  text-align: right;
  margin-bottom: 24px;
}
</style>
