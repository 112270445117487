<template>
  <div class="exam-transfer">
    <div class="model-desc mt30">
      <div>
        本报告显示的对比考试为：{{ settingName }}
        <el-button type="text" @click="setting">设置</el-button>
      </div>
    </div>
    <el-dialog
      title="设置对比考试"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose"
      @open="stopSrcoll()"
      @close="openSrcoll()"
    >
      <div>
        <div class="filter">
          <template v-if="newExam && !isAp">
            <div class="selects-item">
              <div class="label">考试模式：</div>
              <el-select
                v-model="examMode"
                placeholder="全部考试"
                style="width: 176px"
                clearable
                @change="getstatcompare"
              >
                <el-option :label="'普通考试'" :value="0"> </el-option>
                <el-option :label="'新高考'" :value="1"> </el-option>
              </el-select>
            </div>
          </template>

          <div class="selects-item">
            <div class="label">考试类型：</div>
            <el-select
              v-model="examTypes"
              placeholder="全部考试"
              style="width: 176px"
              clearable
              multiple
              collapse-tags
              @change="getstatcompare"
            >
              <el-option
                v-for="item in examTypeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div v-if="subject" class="selects-item">
            <div class="label">科目：</div>
            <el-select
              v-model="subjectIds"
              placeholder="全部考试"
              clearable
              multiple
              collapse-tags
              style="width: 176px"
              @change="getstatcompare"
            >
              <el-option
                v-for="item in subjectList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="selects-item">
            <el-input
              v-model="inputText"
              style="width: 220px"
              clearable
              placeholder="请搜索"
              @input="getstatcompare"
            ></el-input>
          </div>
        </div>
        <div v-loading="loading">
          <div class="transfer">
            <div class="transfer-item" style="margin-right: 8px">
              <div class="header-left">
                <el-button type="text" @click="allCheck">全选</el-button>
              </div>
              <div class="content">
                <el-checkbox-group v-model="checkList">
                  <el-checkbox
                    v-for="(item, index) in examList"
                    :key="index"
                    :label="item.id"
                  >
                    <div class="content-item">
                      <div class="name">{{ item.name }}</div>
                      <div class="msg">
                        <div>{{ getExamType(item.examExamType) }}</div>
                        <div>{{ item.examTotal }}人</div>
                        <div>{{ item.examDate }}</div>
                      </div>
                      <div class="subjects">{{ item.subjectStr }}</div>
                    </div>
                  </el-checkbox>
                </el-checkbox-group>
              </div>
            </div>
            <div class="transfer-item">
              <div class="header-right">
                <el-button type="text" class="ft-red" @click="allClear"
                  >清空</el-button
                >
              </div>
              <div class="content">
                <div
                  v-for="(item, index) in chooseList"
                  :key="index"
                  class="checked"
                >
                  <div class="content-item">
                    <div class="name">{{ item.name }}</div>
                    <div class="msg">
                      <div>{{ getExamType(item.examExamType) }}</div>
                      <div>{{ item.examTotal }}人</div>
                      <div>{{ item.examDate }}</div>
                    </div>
                    <div class="subjects">{{ item.subjectStr }}</div>
                  </div>
                  <div class="del" @click="delItem(index)">
                    <i class="el-icon-close"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getstatdevelopanalysiscomparelist,
  getstatsubjectoption,
} from "@/core/api/academic/common";
import { examTypeOptions } from "@/core/util/constdata";
export default {
  props: {
    subject: {
      type: Boolean,
      default: false,
    },
    newExam: {
      type: Boolean,
      default: false,
    },
    subjectId: {
      type: [String, Number, null],
      default: null,
    },
    classNum: {
      type: [String, Number, null],
      default: null,
    },
    examArr: {
      type: Array,
      default: () => [],
    },
    statId: {
      type: [String, Number, null],
      default: null,
    },
    requestPage: {
      type: [String, Number, null],
      default: null,
    },
    isAp: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogVisible: false,
      examTypes: [],
      settingName: "",
      subjectIds: [],
      examTypeOptions: [...examTypeOptions],
      loading: false,
      subjectList: [],
      examList: [],
      checkList: [],
      chooseList: [],
      examMode: "",
      inputText: "",
    };
  },
  watch: {
    checkList: {
      deep: true,
      handler() {
        this.chooseList = this.examList.filter((item) =>
          this.checkList.includes(item.id)
        );
      },
    },
    examArr: {
      deep: true,
      handler() {
        const names = this.examArr
          .filter((item) => item.id != this.statId)
          .map((item) => item.name);
        this.settingName = names.toString();
      },
    },
  },

  mounted() {},
  destroyed() {},
  methods: {
    stopSrcoll() {
      document.body.classList.add("no-scroll");
    },

    openSrcoll() {
      document.body.classList.remove("no-scroll");
    },

    getExamType(type) {
      if (type) {
        const data = examTypeOptions.find((item) => item.value === type);
        return data.label;
      }
    },
    handleClose() {
      this.dialogVisible = false;
    },
    setting() {
      this.dialogVisible = true;

      if (this.subjectId != null) {
        this.getstatcompare();
      } else {
        this.getSubjectList();
      }
    },
    allClear() {
      this.checkList = [];
    },
    allCheck() {
      this.checkList = [...this.examList.map((item) => item.id)];
    },
    async getSubjectList() {
      const res = await getstatsubjectoption({
        id: this.statId,
        hasTotal: 0,

        requestPage: this.requestPage,
      });
      this.subjectList = res.data.data;
      this.getstatcompare();
    },
    submit() {
      this.$emit("submit", this.checkList);
      let arr = this.chooseList.map((item) => item.name);
      this.settingName = arr.toString();
      this.dialogVisible = false;
    },
    delItem(index) {
      this.checkList.splice(index, 1);
    },
    async getstatcompare() {
      this.loading = true;
      const schoolId = this.$route.query.schoolId;
      const res = await getstatdevelopanalysiscomparelist({
        statId: this.statId,
        examTypes: this.examTypes.length ? this.examTypes.toString() : null,
        classNum: this.classNum ? this.classNum : null,
        examMode: this.isAp ? 1 : this.examMode,
        input: this.inputText,
        subjectIds: this.subjectId
          ? this.subjectId
          : this.subjectIds.length
          ? this.subjectIds.toString()
          : null,
        viewSchoolId: schoolId ? schoolId : null,
      });
      if (this.isAp) {
        this.examList = res.data.data.filter((item) => item.scoreType == 1);
      } else {
        this.examList = res.data.data;
      }
      const ids = this.examArr.map((item) => item.id);
      this.checkList = ids.filter((item) => item != this.statId);
      this.chooseList = this.examList.filter((item) =>
        this.checkList.includes(item.id)
      );
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
.filter {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
  .selects-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 10px;
  }
}
.transfer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  .transfer-item {
    width: 48%;
    border: 1px solid #dee1e3;
    .header-left {
      height: 38px;
      background-color: #f5f7fa;
      text-align: left;
      box-sizing: border-box;
      line-height: 38px;
      padding: 0 18px;
    }
    .header-right {
      box-sizing: border-box;
      padding: 0 18px;
      line-height: 38px;
      height: 38px;
      background-color: #f5f7fa;
      text-align: right;
    }
    .content {
      height: 280px;
      overflow-y: auto;
      box-sizing: border-box;
      padding: 18px;
      .checked {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 32px;
        .del {
          cursor: pointer;
          i {
            &:hover {
              color: #2474ed;
            }
          }
        }
      }
      .content-item {
        .name {
          font-size: 14px;
          color: #101011;
          font-weight: bold;
          margin-bottom: 12px;
          line-height: 1;
        }
        .msg {
          display: flex;
          align-items: center;
          line-height: 1;
          margin-bottom: 18px;
          div {
            margin-right: 18px;
            font-size: 12px;
            color: #797984;
          }
        }
        .subjects {
          line-height: 1.5;
          font-size: 12px;
          color: #797984;
          white-space: break-spaces;
        }
      }
    }
  }
}
</style>
<style scoped>
.el-checkbox {
  display: flex !important;
  align-items: flex-start !important;
  justify-content: flex-start !important;
  width: 100% !important;
  margin-bottom: 32px;
}
::v-deep .el-popup-parent--hidden {
  overflow: visible;
}
</style>
