var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"stat-contain"},[_c('div',{staticClass:"topCard"},[_c('tag',{attrs:{"define-tag":""}},[_vm._v(_vm._s(_vm.schoolName))]),_c('div',{staticClass:"title-box"},[_c('div',{staticClass:"title"},[_c('div',{staticClass:"new-exam-tag"},[_vm._v("新高考")]),_vm._v(" "+_vm._s(_vm.topData.name)+" "),_c('div',{class:['sub-tag', _vm.topData.type == 99 ? 'otherTitle' : 'general']},[_vm._v(" "+_vm._s(_vm.getExamType(_vm.topData.type))+" ")])])]),_c('div',{staticClass:"back",on:{"click":_vm.back}},[_c('i',{staticClass:"el-icon-arrow-left"}),_vm._v("返回 ")]),_c('div',{staticClass:"sub-title"},[_c('div',[_vm._v(" 年级："),(_vm.level == 2)?_c('span',[_vm._v("初")]):_vm._e(),(_vm.level == 3)?_c('span',[_vm._v("高")]):_vm._e(),_vm._v(_vm._s(_vm.topData.year)+"级 ")]),_c('div',[_vm._v("时间："+_vm._s(_vm.topData.examDate))])]),_c('div',{staticClass:"model-title"},[_vm._v("联考报告")]),_c('div',{staticClass:"new-dashboard"},[_c('div',{class:[
          'box',
          'normal',
          _vm.topData.hasPhysics != 1 || _vm.topData.hasHistory != 1
            ? 'flex-box'
            : '',
        ]},[_c('div',{staticClass:"item border-item"},[_c('div',{staticClass:"item-title"},[_vm._v(_vm._s(_vm.topData.statTotal)),_c('span',[_vm._v("名")])]),_c('div',[_vm._v("参统考生")])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"item-title"},[_vm._v(" "+_vm._s(_vm.topData.eleccombCount)),_c('span',[_vm._v("个")])]),_c('div',[_vm._v("参考组合")])])]),(_vm.topData.isSchoolDisplayReportSchoolRank == 1)?_c('div',{class:[
          'box',
          'normal',
          _vm.topData.hasPhysics != 1 || _vm.topData.hasHistory != 1
            ? 'flex-box'
            : '',
        ]},[(_vm.topData.hasPhysics == 1)?_c('div',{staticClass:"item border-item"},[_c('div',{staticClass:"item-title"},[_vm._v(_vm._s(_vm.topData.physicsRank))]),_c('div',[_vm._v("物理类")])]):_vm._e(),(_vm.topData.hasHistory == 1)?_c('div',{staticClass:"item"},[_c('div',{staticClass:"item-title"},[_vm._v(" "+_vm._s(_vm.topData.historyRank)+" ")]),_c('div',[_vm._v("历史类")])]):_vm._e()]):_vm._e(),(_vm.topData.hasPhysics == 1 || _vm.topData.hasHistory == 1)?_c('div',{staticClass:"vertical-text",staticStyle:{"background":"#f3fcf8"}},[_vm._v(" 平 均 分 ")]):_vm._e(),(_vm.topData.hasPhysics == 1 || _vm.topData.hasHistory == 1)?_c('div',{class:[
          'box',
          'success',
          _vm.topData.hasPhysics != 1 || _vm.topData.hasHistory != 1
            ? 'flex-box'
            : '',
        ]},[(_vm.topData.hasPhysics == 1)?[_c('div',{class:[
              'item',
              _vm.topData.historyMaxScoreOri && _vm.topData.historyMaxScore
                ? 'border-item'
                : '',
            ]},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"sub-item sub-border-item"},[_c('div',{staticClass:"item-title"},[_vm._v(" "+_vm._s(_vm.topData.physicsAverageScoreOri || "-")+" ")]),_c('div',[_vm._v("原始分")])]),_c('div',{staticClass:"sub-item"},[_c('div',{staticClass:"item-title"},[_vm._v(" "+_vm._s(_vm.topData.physicsAverageScore || "-")+" ")]),_c('div',[_vm._v("赋分")])])]),_c('div',[_vm._v("物理类")])])]:_vm._e(),(_vm.topData.hasHistory == 1)?[_c('div',{staticClass:"item"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"sub-item sub-border-item"},[_c('div',{staticClass:"item-title"},[_vm._v(" "+_vm._s(_vm.topData.historyAverageScoreOri || "-")+" ")]),_c('div',[_vm._v("原始分")])]),_c('div',{staticClass:"sub-item"},[_c('div',{staticClass:"item-title"},[_vm._v(" "+_vm._s(_vm.topData.historyAverageScore || "-")+" ")]),_c('div',[_vm._v("赋分")])])]),_c('div',[_vm._v("历史类")])])]:_vm._e()],2):_vm._e(),(_vm.topData.hasPhysics == 1 || _vm.topData.hasHistory == 1)?_c('div',{staticClass:"vertical-text"},[_vm._v(" 最 高 分 ")]):_vm._e(),(_vm.topData.hasPhysics == 1 || _vm.topData.hasHistory == 1)?_c('div',{class:[
          'box',
          'other',
          _vm.topData.hasPhysics != 1 || _vm.topData.hasHistory != 1
            ? 'flex-box'
            : '',
        ]},[(_vm.topData.hasPhysics == 1)?[_c('div',{class:[
              'item',
              _vm.topData.historyMaxScoreOri && _vm.topData.historyMaxScore
                ? 'border-item'
                : '',
            ]},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"sub-item sub-border-item"},[_c('div',{staticClass:"item-title"},[_vm._v(" "+_vm._s(_vm.topData.physicsMaxScoreOri || "-")+" ")]),_c('div',[_vm._v("原始分")])]),_c('div',{staticClass:"sub-item"},[_c('div',{staticClass:"item-title"},[_vm._v(" "+_vm._s(_vm.topData.physicsMaxScore || "-")+" ")]),_c('div',[_vm._v("赋分")])])]),_c('div',[_vm._v("物理类")])])]:_vm._e(),(_vm.topData.hasHistory == 1)?[_c('div',{staticClass:"item"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"sub-item sub-border-item"},[_c('div',{staticClass:"item-title"},[_vm._v(" "+_vm._s(_vm.topData.historyMaxScoreOri || "-")+" ")]),_c('div',[_vm._v("原始分")])]),_c('div',{staticClass:"sub-item"},[_c('div',{staticClass:"item-title"},[_vm._v(" "+_vm._s(_vm.topData.historyMaxScore || "-")+" ")]),_c('div',[_vm._v("赋分")])])]),_c('div',[_vm._v("历史类")])])]:_vm._e()],2):_vm._e(),(_vm.$route.query.examType != 1)?[(_vm.topData.hasPhysics == 1 || _vm.topData.hasHistory == 1)?_c('div',{staticClass:"vertical-text",staticStyle:{"background":"#f3f8ff"}},[_vm._v(" 高 分 率 ")]):_vm._e(),(_vm.topData.hasPhysics == 1 || _vm.topData.hasHistory == 1)?_c('div',{class:[
            'box',
            'normal',
            _vm.topData.hasPhysics != 1 || _vm.topData.hasHistory != 1
              ? 'flex-box'
              : '',
          ]},[(_vm.topData.hasPhysics == 1)?[_c('div',{class:[
                'item',
                _vm.topData.historyMaxScoreOri && _vm.topData.historyMaxScore
                  ? 'border-item'
                  : '',
              ]},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"sub-item sub-border-item"},[_c('div',{staticClass:"item-title"},[_vm._v(" "+_vm._s(_vm.topData.physicsMaxScoreOri || "-")+" ")]),_c('div',[_vm._v("原始分")])]),_c('div',{staticClass:"sub-item"},[_c('div',{staticClass:"item-title"},[_vm._v(" "+_vm._s(_vm.topData.physicsMaxScore || "-")+" ")]),_c('div',[_vm._v("赋分")])])]),_c('div',[_vm._v("物理类")])])]:_vm._e(),(_vm.topData.hasHistory == 1)?[_c('div',{staticClass:"item"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"sub-item sub-border-item"},[_c('div',{staticClass:"item-title"},[_vm._v(" "+_vm._s(_vm.topData.historyMaxScoreOri || "-")+" ")]),_c('div',[_vm._v("原始分")])]),_c('div',{staticClass:"sub-item"},[_c('div',{staticClass:"item-title"},[_vm._v(" "+_vm._s(_vm.topData.historyMaxScore || "-")+" ")]),_c('div',[_vm._v("赋分")])])]),_c('div',[_vm._v("历史类")])])]:_vm._e()],2):_vm._e()]:_vm._e()],2)],1),(_vm.topData.year)?[(_vm.totalSegment)?_c('Branch',{attrs:{"id":"Branch","top-data":_vm.topData,"total-segment":_vm.totalSegment}}):_vm._e(),_c('Subject',{attrs:{"id":"Subject"}}),(_vm.topData.statClass == 1)?_c('classVue',{attrs:{"id":"classVue"}}):_vm._e(),(_vm.topData.statClass == 1)?_c('balance',{attrs:{"id":"balance"}}):_vm._e(),(_vm.topData.statClass == 1)?_c('highScore',{attrs:{"id":"highScore"}}):_vm._e(),_c('development',{attrs:{"id":"development"}})]:_vm._e(),_c('pageNav',{attrs:{"menu-list":_vm.menuList}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }