<template>
  <div ref="compeition" v-loading="loading" class="compeition">
    <div class="sub-title">组合竞争力</div>
    <div class="operate">
      <div class="group">
        <a-radio-group
          v-model="category"
          button-style="solid"
          @change="categoryChange"
        >
          <a-radio-button
            v-for="(item, index) in cateOptions"
            :key="index"
            :value="item.id"
          >
            {{ item.name }}
          </a-radio-button>
        </a-radio-group>
        <a-select
          v-model="groupId"
          not-found-content="暂无数据"
          style="width: 120px; margin-left: 10px"
          @change="groupChange"
        >
          <a-select-option
            v-for="(item, index) in groupList"
            :key="index"
            :value="item.id"
          >
            {{
              ["物理类", "历史类"].includes(item.name) ? "全部组合" : item.name
            }}
          </a-select-option>
        </a-select>
      </div>
      <a-radio-group
        v-model="type"
        style="margin-bottom: 18px"
        button-style="solid"
        @change="typeChange"
      >
        <a-radio-button value="1">平均分</a-radio-button>
        <a-radio-button value="2">比 率</a-radio-button>
        <a-radio-button value="3">更多指标</a-radio-button>
      </a-radio-group>
    </div>
    <div class="dataSource">
      <template v-if="[98, 99].includes(eleccombId)">
        数据源：
        <a-radio-group v-model="dataSource" @change="dataSourceChange">
          <a-radio :value="1"> 原始分 </a-radio>
          <a-radio :value="2"> 赋分 </a-radio>
        </a-radio-group>
      </template>
    </div>
    <exTable
      :key="type + '' + category + dataSource"
      :columns.sync="columns"
      :data-source="allData"
      :show-last-record="false"
      style="margin-bottom: 24px"
    />

    <div class="select">
      <a-select
        v-if="['2', '3'].includes(type)"
        v-model="selectValue"
        style="width: 150px"
        @change="selectChange"
      >
        <a-select-option
          v-for="(item, index) in selectOptions"
          :key="index"
          :value="item.value"
          >{{ item.label }}
        </a-select-option>
      </a-select>
    </div>
    <ChartContainer
      v-if="chartSeries"
      :key="chartKey"
      :define-options="defineOptions"
      :series="chartSeries"
    />
    <no-data v-else message="暂无数据"></no-data>
    <div class="tip">
      <div>注：</div>
      <div>
        <div>
          1、学校名次指在学校参考的物理类或历史类的赋分均分，在所参考的大类中排名的情况；
        </div>
        <div>
          2、物理类或历史类
          大类排名指各校参考的物理类或历史类的赋分均分在所参考的大类中排名的情况；具体组合
          大类排名 指各校参考的组合的赋分均分在所在参考大类中的排名情况
        </div>
        <div>
          3、组合排名指各校参考的组合原始分平均分在本组合学校中的排名情况。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChartContainer from "../components/ChartContainer.vue";
import exTable from "../../components/exTable.vue";
import {
  getstateleccateoption,
  getstateleccomboptionV2,
} from "@/core/api/academic/common";
import { eleccombcompetitive } from "@/core/api/newExamAcademic/union/index";
export default {
  name: "",
  components: {
    ChartContainer,
    exTable,
  },
  props: {
    eleccombId: {
      type: [String, Number],
      default: "",
    },
    hasPhysics: {
      type: Number,
      default: 0,
    },
    hasHistory: {
      type: Number,
      default: 0,
    },
    topData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      selectValue: null,
      chartKey: "",
      dataSource: 2,
      category: "1",
      chartSeries: [],
      columns: [],
      enmuCols: [
        {
          title: "组合",
          align: "center",
          dataIndex: "eleccombName",
          customRender: (text, record, index) => {
            const obj = {
              children: record.eleccombName,
              attrs: { rowSpan: 1 },
            };
            obj.attrs.rowSpan = this.renderCells(
              record.eleccombName,
              this.allData,
              "eleccombName",
              index
            );
            return obj;
          },
        },
        {
          title: "学校",
          align: "center",
          dataIndex: "schoolName",
        },
        {
          title: "统计人数",
          align: "center",
          dataIndex: "statTotal",
        },
      ],
      oldData: [],
      tableData: [],
      cateOptions: [],
      groupId: null,
      groupList: [],
      loading: false,
      type: "1",
      allData: [],
      selectOptions: [],
      defineOptions: {},
      otherOptions: [
        {
          label: "标准差",
          value: "standardDeviation",
        },
        {
          label: "最高分",
          value: "highestScore",
        },
        {
          label: "最低分",
          value: "lowestScore",
        },
        {
          label: "超均率",
          value: "overAverageRate",
        },
      ],
    };
  },
  mounted() {
    if ([98, 99].includes(this.eleccombId)) {
      this.dataSource = 2;
    }
    this.getCondition();
  },
  methods: {
    dataSourceChange() {
      this.getData();
    },
    renderCells(text, data, key, index) {
      if (data.length < 1) {
        return 1;
      }
      if (text === "" || text === null) {
        data[index].rowNum = 1;
        return 1;
      }
      // 上一行该列数据是否一样
      if (index !== 0 && text === data[index - 1][key]) {
        data[index].rowNum = 0;
        return 0;
      }
      let rowSpan = 1;
      // 判断下一行是否相等
      for (let i = index + 1; i < data.length; i++) {
        if (text !== data[i][key]) {
          break;
        }
        rowSpan++;
      }
      data[index].rowNum = rowSpan;
      return rowSpan;
    },
    groupChange() {
      this.getData();
    },
    typeChange() {
      let data = this.allData;
      // if (this.dataSource == 1) {
      //   data = this.allData.filter(
      //     (item) => !["物理类", "历史类"].includes(item.eleccombName)
      //   );
      // }
      const xAxis = [...new Set(data.map((item) => item.eleccombName))];
      this.defineOptions = {
        dataZoom: [
          //滚动条
          {
            show: xAxis.length > 25,
            type: "slider",
            realtime: true,
            startValue: 0, // 重点
            // 重点-dataX.length表示x轴数据长度
            endValue: xAxis.length - (xAxis.length - 25),
            xAxisIndex: [0],
            bottom: "10px",
            left: "center",
          },
        ],
        color: ["#5B8FF9", "#5AD8A6", "#5D7092", "#F6BD16", "#E8684A"],
        xAxis: [
          {
            type: "category",
            data: xAxis,
            axisPointer: {
              type: "shadow",
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              rotate: xAxis.length > 15 ? 25 : 0,
            },
          },
        ],
      };
      if (this.type == 1) {
        this.columns = [
          ...this.enmuCols,
          {
            title: "平均分",
            align: "center",
            dataIndex:
              this.dataSource == 1 ? "averageScoreOri" : "averageScore",
          },
        ];
        this.setAvg();
        if (this.$parent.topData.isSchoolDisplayReportSchoolRank == 1) {
          this.columns = this.columns.concat([
            {
              title: "大类排名",
              align: "center",
              dataIndex:
                this.dataSource == 1 ? "eleccateRankOri" : "eleccateRank",
            },
            {
              title: "组合内排名",
              align: "center",
              dataIndex:
                this.dataSource == 1 ? "eleccombRankOri" : "eleccombRank",
            },
          ]);
        }
      } else if (this.type == 2) {
        this.selectOptions = this.ratios.map((item) => ({
          label: item.name,
          value: item.name,
        }));
        this.columns = [
          ...this.enmuCols,
          ...this.ratios.map((item) => {
            let children = [
              {
                title: "人数",
                align: "center",
                customRender: (text, record) => {
                  return record.ratios.find((it) => it.name == item.name)[
                    this.dataSource == 1 ? "totalOri" : "total"
                  ];
                },
              },
              {
                title: "占比（%）",
                align: "center",
                customRender: (text, record) => {
                  return record.ratios.find((it) => it.name == item.name)[
                    this.dataSource == 1 ? "proportionOri" : "proportion"
                  ];
                },
              },
            ];
            if (this.$parent.topData.isSchoolDisplayReportSchoolRank == 1) {
              children.push({
                title: "排名",
                align: "center",
                customRender: (text, record) => {
                  return record.ratios.find((it) => it.name == item.name)[
                    this.dataSource == 1 ? "rankOri" : "rank"
                  ];
                },
              });
            }
            return {
              title: item.name + "（整体）",
              align: "center",
              children: [...children],
            };
          }),
        ];
        if (this.ratios.length) {
          this.selectValue = this.ratios[0].name;
          this.setRatios();
        } else {
          this.chartSeries = null;
        }
      } else {
        this.columns = [
          ...this.enmuCols,
          {
            title: "最高分",
            align: "center",
            dataIndex:
              this.dataSource == 1 ? "highestScoreOri" : "highestScore",
          },
          {
            title: "最低分",
            align: "center",
            dataIndex: this.dataSource == 1 ? "lowestScoreOri" : "lowestScore",
          },
          {
            title: "全距",
            align: "center",
            dataIndex: this.dataSource == 1 ? "rangeScoreOri" : "rangeScore",
          },
          {
            title: "中位数",
            align: "center",
            dataIndex: this.dataSource == 1 ? "medianOri" : "median",
          },
          {
            title: "离均差（整体）",
            align: "center",
            dataIndex:
              this.dataSource == 1
                ? "deviationFromMeanOri"
                : "deviationFromMean",
          },
          {
            title: "超均率（%）（整体）",
            align: "center",
            dataIndex:
              this.dataSource == 1 ? "overAverageRateOri" : "overAverageRate",
          },
          {
            title: "标准差",
            align: "center",
            dataIndex:
              this.dataSource == 1
                ? "standardDeviationOri"
                : "standardDeviation",
          },
          {
            title: "差异系数",
            align: "center",
            dataIndex:
              this.dataSource == 1
                ? "coefficientOfVariationOri"
                : "coefficientOfVariation",
          },
        ];
        this.selectOptions = this.otherOptions;
        this.selectValue = this.otherOptions[0].value;
        this.setOther();
      }
    },
    async getCate() {
      const res = await getstateleccateoption({
        id: this.$route.query.id,
        hasPhysicsCate: this.hasPhysics,
        viewSchoolId: this.$route.query.schoolId,
        hasHistoryCate: this.hasHistory,
      }).catch(() => {});

      this.cateOptions = res.data.data;
      if (res.data.data.length) {
        this.category = res.data.data[0].id;
        this.getGroupList();
      }
    },
    async getCondition() {
      if (this.hasPhysics == 1) {
        this.category = 1;
      } else if (this.hasHistory == 1) {
        this.category = 2;
      }
      this.$forceUpdate();
      this.getCate();
    },
    async getGroupList() {
      const res = await getstateleccomboptionV2({
        id: this.$route.query.id,
        eleccateId: this.category,
        viewSchoolId: this.$route.query.schoolId,
        hasCate: 1,
      });
      this.groupList = res.data.data;
      if (this.groupList.length) {
        this.groupId = this.groupList[0].id;
        this.getData();
      }
    },
    async getData() {
      try {
        this.loading = true;
        const res = await eleccombcompetitive({
          eleccombId: this.groupId,
          id: this.$route.query.id,
          viewSchoolId: this.$route.query.schoolId,
          dataSource: this.dataSource,
        });
        this.loading = false;
        if (res.data.data.length) {
          this.allData = res.data.data;
          this.ratios = res.data.data[0].ratios;
          this.typeChange();
        }
      } catch {
        this.loading = false;
      }
    },
    setAvg() {
      let data = this.allData;
      if (this.dataSource == 1) {
        data = this.allData.filter(
          (item) => !["物理类", "历史类"].includes(item.eleccombName)
        );
      }
      const key = this.dataSource == 1 ? "averageScoreOri" : "averageScore";
      this.chartSeries = [
        {
          name: "本校",
          type: "bar",
          barMaxWidth: 40,
          label: {
            show: true,
            position: "top",
            color: "inherit",
          },
          data: data
            .filter((item) => item.schoolName == "本校")
            .map((item) => item[key]),
        },
        {
          name: "上位学校",
          type: "line",
          data: data
            .filter((item) => item.schoolName == "上位学校")
            .map((item) => item[key]),
        },
        {
          name: "整体高位学校",
          type: "line",
          data: data
            .filter((item) => item.schoolName == "整体高位学校")
            .map((item) => item[key]),
        },
        {
          name: "下位学校",
          type: "line",
          data: data
            .filter((item) => item.schoolName == "下位学校")
            .map((item) => item[key]),
        },
      ];
      if (this.topData.isSchoolDisplayReportUnionAverageScore == 1) {
        this.chartSeries.push({
          name: "整体",
          type: "line",
          data: data
            .filter((item) => item.schoolName == "整体")
            .map((item) => item[key]),
        });
      }
      this.chartKey = Math.random();
    },
    setRatios() {
      let data = this.allData;
      // if (this.dataSource == 1) {
      //   data = this.allData.filter(
      //     (item) => !["物理类", "历史类"].includes(item.eleccombName)
      //   );
      // }
      const key = this.dataSource == 1 ? "proportionOri" : "proportion";
      this.chartSeries = [
        {
          name: "本校",
          type: "bar",
          barMaxWidth: 40,
          label: {
            show: true,
            position: "top",
            color: "inherit",
          },
          data: data
            .filter((item) => item.schoolName == "本校")
            .map((item) => {
              return item.ratios.find((it) => it.name == this.selectValue)[key];
            }),
        },
        {
          name: "上位学校",
          type: "line",
          data: data
            .filter((item) => item.schoolName == "上位学校")
            .map(
              (item) =>
                item.ratios.find((it) => it.name == this.selectValue)[key]
            ),
        },
        {
          name: "整体高位学校",
          type: "line",
          data: data
            .filter((item) => item.schoolName == "整体高位学校")
            .map(
              (item) =>
                item.ratios.find((it) => it.name == this.selectValue)[key]
            ),
        },
        {
          name: "下位学校",
          type: "line",
          data: data
            .filter((item) => item.schoolName == "下位学校")
            .map(
              (item) =>
                item.ratios.find((it) => it.name == this.selectValue)[key]
            ),
        },
        {
          name: "整体",
          type: "line",
          data: data
            .filter((item) => item.schoolName == "整体")
            .map(
              (item) =>
                item.ratios.find((it) => it.name == this.selectValue)[key]
            ),
        },
      ];
      this.chartKey = Math.random();
    },
    setOther() {
      let data = this.allData;
      // if (this.dataSource == 1) {
      //   data = this.allData.filter(
      //     (item) => !["物理类", "历史类"].includes(item.eleccombName)
      //   );
      // }
      let key =
        this.dataSource == 1 ? this.selectValue + "Ori" : this.selectValue;
      this.chartSeries = [
        {
          name: "本校",
          type: "bar",
          barMaxWidth: 40,
          label: {
            show: true,
            position: "top",
            color: "inherit",
          },
          data: data
            .filter((item) => item.schoolName == "本校")
            .map((item) => item[key]),
        },
        {
          name: "上位学校",
          type: "line",
          data: data
            .filter((item) => item.schoolName == "上位学校")
            .map((item) => item[key]),
        },
        {
          name: "整体高位学校",
          type: "line",
          data: data
            .filter((item) => item.schoolName == "整体高位学校")
            .map((item) => item[key]),
        },
        {
          name: "下位学校",
          type: "line",
          data: data
            .filter((item) => item.schoolName == "下位学校")
            .map((item) => item[key]),
        },
        {
          name: "整体",
          type: "line",
          data: data
            .filter((item) => item.schoolName == "整体")
            .map((item) => item[key]),
        },
      ];
      this.chartKey = Math.random();
    },
    radioChange() {
      let data = this.allData;
      if (this.dataSource == 1) {
        data = this.allData.filter(
          (item) => !["物理类", "历史类"].includes(item.eleccombName)
        );
      }
      const xAxis = [...new Set(data.map((item) => item.eleccombName))];
      this.defineOptions = {
        dataZoom: [
          //滚动条
          {
            show: xAxis.length > 25,
            type: "slider",
            realtime: true,
            startValue: 0, // 重点
            // 重点-dataX.length表示x轴数据长度
            endValue: xAxis.length - (xAxis.length - 25),
            xAxisIndex: [0],
            bottom: "10px",
            left: "center",
          },
        ],
        color: ["#5B8FF9", "#5AD8A6", "#5D7092", "#F6BD16", "#E8684A"],
        xAxis: [
          {
            type: "category",
            data: xAxis,
            axisPointer: {
              type: "shadow",
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              rotate: xAxis.length > 15 ? 25 : 0,
            },
          },
        ],
      };
      if (this.type == 1) {
        this.columns = [
          ...this.enmuCols,
          {
            title: "平均分",
            align: "center",
            dataIndex:
              this.dataSource == 1 ? "averageScoreOri" : "averageScore",
          },
        ];
        this.setAvg();
        if (this.$parent.topData.isSchoolDisplayReportSchoolRank == 1) {
          this.columns = this.columns.concat([
            {
              title: "大类排名",
              align: "center",
              dataIndex:
                this.dataSource == 1 ? "eleccateRankOri" : "eleccateRank",
            },
            {
              title: "组合内排名",
              align: "center",
              dataIndex:
                this.dataSource == 1 ? "eleccombRankOri" : "eleccombRank",
            },
          ]);
        }
      } else if (this.type == 2) {
        this.columns = [
          ...this.enmuCols,
          ...this.ratios.map((item) => {
            let children = [
              {
                title: "人数",
                align: "center",
                customRender: (text, record) => {
                  let data = record.ratios.find((it) => it.name == item.name);
                  return data[this.dataSource == 1 ? "totalOri" : "total"];
                },
              },
              {
                title: "占比（%）",
                align: "center",
                customRender: (text, record) => {
                  return record.ratios.find((it) => it.name == item.name)[
                    this.dataSource == 1 ? "proportionOri" : "proportion"
                  ];
                },
              },
            ];
            if (this.$parent.topData.isSchoolDisplayReportSchoolRank == 1) {
              children.push({
                title: "排名",
                align: "center",
                customRender: (text, record) => {
                  return record.ratios.find((it) => it.name == item.name)[
                    this.dataSource == 1 ? "rankOri" : "rank"
                  ];
                },
              });
            }
            return {
              title: item.name + "（整体）",
              align: "center",
              children: [...children],
            };
          }),
        ];
      } else {
        this.columns = [
          ...this.enmuCols,
          {
            title: "最高分",
            align: "center",
            dataIndex:
              this.dataSource == 1 ? "highestScoreOri" : "highestScore",
          },
          {
            title: "最低分",
            align: "center",
            dataIndex: this.dataSource == 1 ? "lowestScoreOri" : "lowestScore",
          },
          {
            title: "全距",
            align: "center",
            dataIndex: this.dataSource == 1 ? "rangeScoreOri" : "rangeScore",
          },
          {
            title: "中位数",
            align: "center",
            dataIndex: this.dataSource == 1 ? "medianOri" : "median",
          },
          {
            title: "离均差（整体）",
            align: "center",
            dataIndex:
              this.dataSource == 1
                ? "deviationFromMeanOri"
                : "deviationFromMean",
          },
          {
            title: "超均率（%）（整体）",
            align: "center",
            dataIndex:
              this.dataSource == 1 ? "overAverageRateOri" : "overAverageRate",
          },
          {
            title: "标准差",
            align: "center",
            dataIndex:
              this.dataSource == 1
                ? "standardDeviationOri"
                : "standardDeviation",
          },
          {
            title: "差异系数",
            align: "center",
            dataIndex:
              this.dataSource == 1
                ? "coefficientOfVariationOri"
                : "coefficientOfVariation",
          },
        ];
        this.setOther();
      }
    },
    categoryChange() {
      this.getGroupList();
    },
    selectChange() {
      if (this.type == 1) {
        this.setAvg();
      } else if (this.type == 2) {
        this.setRatios();
      } else {
        this.setOther();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../new-exam.module.scss";
.operate {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}
.table {
  margin-bottom: 24px;
}
.dataSource {
  margin-bottom: 24px;
}
.select {
  text-align: right;
  margin-bottom: 24px;
}
.group {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  box-sizing: border-box;
}
</style>
