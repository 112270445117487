<template>
  <div ref="branch" v-loading="loading" class="container">
    <div class="title">整体竞争力</div>
    <div class="sub-title">成绩分布</div>
    <div class="group">
      <a-radio-group
        v-model="category"
        button-style="solid"
        @change="categoryChange"
      >
        <a-radio-button
          v-for="(item, index) in cateOptions"
          :key="index"
          :value="item.id"
        >
          {{ item.name }}
        </a-radio-button>
      </a-radio-group>
      <a-select
        v-model="groupId"
        not-found-content="暂无数据"
        style="width: 120px; margin-left: 10px"
        @change="groupChange"
      >
        <a-select-option
          v-for="(item, index) in groupList"
          :key="index"
          :value="item.id"
        >
          {{
            ["物理类", "历史类"].includes(item.name) ? "全部组合" : item.name
          }}
        </a-select-option>
      </a-select>
    </div>
    <div class="operate">
      数据源：
      <a-radio-group v-model="search.dataSource" @change="dataSourceChange">
        <a-radio :value="1"> 原始分</a-radio>
        <a-radio :value="2"> 赋分 </a-radio>
      </a-radio-group>
      <div class="scoreSegmentation">
        分数段区间：
        <span>上限：</span>
        <a-input v-model="search.high" style="width: 64px" />
        <span>分</span>
        <span>下限：</span>
        <a-input v-model="search.low" style="width: 64px" />
        <span>分</span>
        <span>间隔：</span>
        <a-input v-model="search.interval" style="width: 64px" />
        <span>分</span>
        <a-button type="primary" @click="getData">确定</a-button>
      </div>
    </div>
    <div class="chart">
      <ChartContainer
        :key="chartKey"
        :define-options="defineOptions"
        :series="chartSeries"
      />
    </div>
    <div class="tip" style="margin-bottom: 18px">
      <div class="text indent">
        横轴为分数段区间，从左到右分数从高到低，具体间隔可在右上角自由设置。纵轴是该分数段区间的人数占比(%)，线越在上方的学校代表该分数区间人数占比越高
        查看图表时，可关注高分群体(左侧)哪个学校线越高，则好学生越多，反之低分群体(右侧)哪个学校线越高则后进生越多。
      </div>
    </div>
    <compeition
      :eleccomb-id="groupId"
      :has-physics="topData.hasPhysics"
      :has-history="topData.hasHistory"
      :top-data="topData"
    />
  </div>
</template>

<script>
import {
  getstateleccateoption,
  getstateleccomboptionV2,
} from "@/core/api/academic/common";
import { scoredistribution } from "@/core/api/newExamAcademic/union/index";
import ChartContainer from "../components/ChartContainer.vue";
import compeition from "./compeition.vue";
export default {
  name: "",
  components: {
    ChartContainer,
    compeition,
  },
  props: {
    totalSegment: {
      type: Object,
      default: () => {},
    },
    topData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      cateOptions: [],
      groupId: null,
      groupList: [],
      loading: false,
      category: null,
      search: {
        dataSource: 2,
        high: null,
        low: null,
        interval: null,
      },
      chartKey: Math.random(),
      defineOptions: {},
      chartSeries: [
        {
          type: "line",
          name: "整体高位学校",
          smooth: true,
          data: [],
          label: {
            show: true,
            position: "top",
            color: "inherit",
          },
        },
      ],
    };
  },
  mounted() {
    this.getCondition();
    this.search.high = this.totalSegment.maximum;
    this.search.low = this.totalSegment.minimum;
    this.search.interval = this.totalSegment.gap;
  },
  methods: {
    dataSourceChange() {
      this.getData();
    },
    async getCondition() {
      this.loading = true;
      if (this.$parent.topData.hasPhysics == 1) {
        this.category = 1;
      } else if (this.$parent.topData.hasHistory == 1) {
        this.category = 2;
      }
      this.$forceUpdate();
      this.getCate();
    },
    async getGroupList() {
      const res = await getstateleccomboptionV2({
        id: this.$route.query.id,
        eleccateId: this.category,
        viewSchoolId: this.$route.query.schoolId,
        hasCate: 1,
      });
      this.groupList = res.data.data;
      if (this.groupList.length) {
        this.groupId = this.groupList[0].id;
        this.getData();
      }
    },
    isNumber() {
      return (
        /^[0-9]*$/.test(this.search.high) &&
        /^[0-9]*$/.test(this.search.low) &&
        /^[0-9]*$/.test(this.search.interval)
      );
    },
    async getData() {
      if (!this.isNumber()) {
        this.$message({
          type: "warning",
          message: "请输入正整数",
          showClose: true,
        });
        return false;
      }

      try {
        const res = await scoredistribution({
          ...this.search,
          id: this.$route.query.id,
          eleccombId: this.groupId,
          viewSchoolId: this.$route.query.schoolId,
        });
        if (res.data.data.length) {
          let segments = res.data.data[0].segments;
          this.defineOptions = {
            color: ["#5B8FF9", "#5AD8A6", "#5D7092", "#F6BD16", "#E8684A"],
            dataZoom: [
              //滚动条
              {
                show: segments.length > 25,
                type: "slider",
                realtime: true,
                startValue: 0, // 重点
                // 重点-dataX.length表示x轴数据长度
                endValue: segments.length - (segments.length - 25),
                xAxisIndex: [0],
                bottom: "10px",
                left: "center",
              },
            ],
            grid: {
              containLabel: true,
            },
            xAxis: [
              {
                type: "category",
                data: segments.map((item) => item.segment),
                axisPointer: {
                  type: "shadow",
                },
                axisTick: {
                  show: false,
                },
                axisLabel: {
                  rotate: 45,
                },
              },
            ],
          };

          this.chartKey = Math.random();
          this.chartSeries = res.data.data.map((item) => {
            return {
              type: "line",
              name: item.schoolName,
              smooth: true,
              data: item.segments.map((it) => it.proportion),
              label: {
                show: true,
                position: "top",
                color: "inherit",
              },
            };
          });
        }

        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    categoryChange() {
      this.getGroupList();
    },
    groupChange(id) {
      this.groupId = id;
      this.chartData = [];
      this.getData();
    },
    closeLoading(loadingInstance, io) {
      if (loadingInstance && io) {
        this.$nextTick(() => {
          loadingInstance.close();
          io.unobserve(this.$refs.branch);
        });
      }
    },
    async getCate() {
      const res = await getstateleccateoption({
        id: this.$route.query.id,
        hasPhysicsCate: this.$parent.topData.hasPhysics,
        hasHistoryCate: this.$parent.topData.hasHistory,
        viewSchoolId: this.$route.query.schoolId,
      }).catch(() => {
        // this.closeLoading(loadingInstance, io);
      });

      this.cateOptions = res.data.data;
      if (res.data.data.length) {
        this.category = res.data.data[0].id;
        this.getGroupList();
      }
      // this.closeLoading(loadingInstance, io);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../new-exam.module.scss";
.group {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  box-sizing: border-box;
  padding: 18px;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #d5d6db;
  .btn {
    width: 82px;
    height: 32px;
    border-radius: 4px;
    border: 1px solid #d5d6db;
    background-color: #fff;
    line-height: 32px;
    text-align: center;
    cursor: pointer;
    color: #0a1119;
    font-size: 14px;
    margin-left: 8px;
  }
  .btn-active {
    color: #ffffff;
    background: #2474ed;
  }
}
.operate {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 18px;
  .scoreSegmentation {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 40px;
    span {
      margin: 0 8px;
    }
  }
}
.typeChange {
  display: flex;
  justify-content: flex-end;
}
.chart {
  margin-bottom: 24px;
}
</style>
