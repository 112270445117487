<template>
  <div
    ref="classCompetitiveness"
    v-lazyChart="{ fn: getCondition }"
    class="container"
  >
    <div class="title">班级竞争力</div>
    <div class="filter-box">
      <div class="subjectList">
        <div
          v-for="(item, index) in subjectList"
          :key="index"
          :class="['subject', search.subjectId === item.id ? 'active' : '']"
          @click="handleClick(item)"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="operate">
        <a-select
          v-model="search.cate"
          not-found-content="暂无数据"
          style="width: 120px; margin-right: 8px"
          :disabled="cateDisabled"
          @change="handleChange"
        >
          <a-select-option v-if="search.subjectId != 0" :value="0">
            全部类别
          </a-select-option>
          <a-select-option
            v-for="(item, index) in eleccateList"
            :key="index"
            :value="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
        <eleccomb
          style="margin-right: 8px"
          :eleccomb-list="resEleccombList"
          @change="resEleccomChange"
        />
        <classNums
          ref="classNums"
          style="margin-right: 8px"
          :placeholder="'选择' + getClassType(search.subjectId) + '班'"
          :request-page="1"
          :class-num-list="classNumList"
          @change="classNumChange"
        />
      </div>
    </div>
    <div class="category">
      <div>
        <template>
          <span>数据源：</span>
          <a-radio-group v-model="search.dataSource" @change="dataSourceChange">
            <a-radio :value="1"> 原始分</a-radio>
            <a-radio
              v-if="![1, 2, 3, 4, 8].includes(search.subjectId)"
              :value="2"
            >
              赋分
            </a-radio>
          </a-radio-group>
        </template>
      </div>
      <a-radio-group
        v-model="search.category"
        button-style="solid"
        @change="categoryChange"
      >
        <a-radio-button :value="1"> 均分分析 </a-radio-button>
        <a-radio-button :value="2"> 比 率 </a-radio-button>
        <a-radio-button
          v-if="topSections.length && search.subjectId != 0"
          :value="3"
        >
          前N名
        </a-radio-button>
        <a-radio-button :value="4"> 更多指标 </a-radio-button>
      </a-radio-group>
    </div>
    <a-table
      v-if="search.subjectId == 0"
      :key="tableKey"
      :loading="loading"
      style="margin-bottom: 24px"
      :columns="columns"
      :pagination="false"
      :scroll="{ x: 'max-content' }"
      :data-source="tableData"
      bordered
    >
      <template slot="unionRank">
        <div>整体排名</div>
        <div v-if="search.subjectId == 0">（赋分）</div>
      </template>
      <template slot="schoolRank">
        <div>校排名</div>
        <div v-if="search.subjectId == 0">（赋分）</div>
      </template>
      <template slot="averageScoreOri">
        <div>平均分</div>
      </template>
      <template slot="highestScoreOri">
        <div>最高分</div>
      </template>
      <template slot="lowestScoreOri">
        <div>最低分</div>
      </template>
      <template slot="rangeScoreOri">
        <div>全距</div>
      </template>
      <template slot="medianOri">
        <div>中位数</div>
      </template>
      <template slot="overAverageRateUnionOri">
        <div>整体超均率</div>
        <div>（原始分）</div>
      </template>
      <template slot="overAverageRateOri">
        <div>校超均率</div>
        <div>（原始分）</div>
      </template>
      <template slot="standardDeviationOri">
        <div>标准差</div>
        <div>（原始分）</div>
      </template>
      <template slot="coefficientOfVariationOri">
        <div>差异系数</div>
        <div>（原始分）</div>
      </template>
    </a-table>
    <!-- <div
      v-if="search.subjectId == 0 && [1, 4].includes(search.category)"
      style="margin-bottom: 16px"
    >
      <span>数据源：</span>
      <a-radio-group v-model="search.dataSource" @change="dataSourceChange">
        <a-radio :value="1"> 原始分</a-radio>
        <a-radio v-if="![1, 2, 3, 4, 8].includes(search.subjectId)" :value="2">
          赋分
        </a-radio>
      </a-radio-group>
    </div> -->
    <div class="select">
      <a-select
        v-if="[2, 4, 3].includes(search.category)"
        v-model="selectValue"
        style="width: 150px"
        @change="selectChange"
      >
        <a-select-option
          v-for="(item, index) in selectOptions"
          :key="index"
          :value="item.value"
        >
          {{ item.label }}
        </a-select-option>
      </a-select>
    </div>
    <ChartContainer
      v-if="defineOptions"
      :key="'chart' + tableKey"
      :define-options="defineOptions"
      :series="series"
    />
    <no-data v-else message="暂无数据"></no-data>
    <a-table
      v-if="search.subjectId > 0"
      :key="tableKey"
      :loading="loading"
      style="margin-bottom: 24px"
      :columns="columns"
      :pagination="false"
      :scroll="{ x: 'max-content' }"
      :data-source="tableData"
      bordered
    >
      <template slot="unionRank">
        <div>整体排名</div>
        <!-- <div>（赋分）</div> -->
      </template>
      <template slot="schoolRank">
        <div>校排名</div>
        <!-- <div>（赋分）</div> -->
      </template>
      <template slot="averageScoreOri">
        <div>平均分</div>
      </template>
      <template slot="highestScoreOri">
        <div>最高分</div>
      </template>
      <template slot="lowestScoreOri">
        <div>最低分</div>
      </template>
      <template slot="rangeScoreOri">
        <div>全距</div>
      </template>
      <template slot="medianOri">
        <div>中位数</div>
      </template>
      <template slot="overAverageRateUnionOri">
        <div>整体超均率</div>
      </template>
      <template slot="overAverageRateOri">
        <div>校超均率</div>
      </template>
      <template slot="standardDeviationOri">
        <div>标准差</div>
      </template>
      <template slot="coefficientOfVariationOri">
        <div>差异系数</div>
      </template>
    </a-table>
    <div class="tip">
      <div>注：</div>
      <div>
        当前结果均以{{ search.dataSource == 1 ? "原始分" : "赋分" }}进行运算
      </div>
    </div>
  </div>
</template>

<script>
import classNums from "../../components/classNums.vue";
import eleccomb from "../../components/eleccomb.vue";
import ChartContainer from "../components/ChartContainer.vue";
import {
  getstatsubjectoption,
  getstateleccomboptionV2,
  getstatclassoption,
  getstateleccateoption,
} from "@/core/api/academic/common";
import { classcompetitive } from "@/core/api/newExamAcademic/union/index";
import { getClassType } from "../../func";
export default {
  name: "ClassCompetitiveness",
  components: {
    classNums,
    eleccomb,
    ChartContainer,
  },
  data() {
    return {
      tableData: [],
      resEleccombIds: [],
      subjectList: [],
      defineOptions: null,
      series: [],
      getClassType: getClassType,
      plainOptions: [
        { label: "原始分", value: 0 },
        { label: "赋分", value: 1 },
      ],
      cateDisabled: false,
      search: {
        subjectId: 0,
        type: 1,
        category: 1,
        dataType: "standardDeviation",
        dataSource: 1,
        cate: 0,
      },
      ratios: [],
      topSections: [],
      resEleccombList: [],
      BoxPlotXAxis: [],
      allChecked: false,
      indeterminate: false,
      columns: [],
      tableKey: Math.random(),
      checkClassNum: [],
      classNumList: [],
      loading: false,
      colSpanNum: 1,
      chartKey: Math.random(),
      eleccateList: [],
      selectOptions: [],
      selectValue: "",
      otherOptions: [
        {
          label: "最高分",
          value: "highestScore",
        },
        {
          label: "最低分",
          value: "lowestScore",
        },
        {
          label: "全距",
          value: "rangeScore",
        },
        {
          label: "中位数",
          value: "median",
        },
        {
          label: "超均率（校）",
          value: "overAverageRate",
        },
        {
          label: "标准差",
          value: "standardDeviation",
        },
        {
          label: "差异系数",
          value: "coefficientOfVariation",
        },
      ],
      otherCols: [
        {
          dataIndex: "highestScore",
          slots: {
            title: "highestScore",
          },
          label: "最高分",
          align: "center",
        },
        {
          dataIndex: "lowestScore",
          slots: {
            title: "lowestScore",
          },
          label: "最低分",
          align: "center",
        },
        {
          dataIndex: "rangeScore",
          slots: {
            title: "rangeScore",
          },
          label: "全距",
          align: "center",
        },
        {
          dataIndex: "median",
          slots: {
            title: "median",
          },
          label: "中位数",
          align: "center",
        },
        {
          dataIndex: "overAverageRateUnion",
          slots: {
            title: "overAverageRateUnion",
          },
          label: "整体超均率",
          align: "center",
        },
        {
          dataIndex: "overAverageRate",
          slots: {
            title: "overAverageRate",
          },
          label: "校超均率",
          align: "center",
        },
        {
          dataIndex: "standardDeviation",
          slots: {
            title: "standardDeviation",
          },
          label: "标准差",
          align: "center",
        },
        {
          dataIndex: "coefficientOfVariation",
          slots: {
            title: "coefficientOfVariation",
          },
          label: "差异系数",
          align: "center",
        },
      ],
    };
  },

  mounted() {
    // this.categoryChange();
  },
  activated() {
    // this.categoryChange();
  },
  methods: {
    selectChange() {
      this.defineOptions = null;
      if (this.search.category == 1) {
        this.setAvg();
      } else if (this.search.category == 2) {
        this.setRatios();
      } else if (this.search.category == 3) {
        this.setTopSections();
      } else if (this.search.category == 4) {
        this.setOther();
      }
    },
    dataSourceChange() {
      this.defineOptions = null;
      if (this.search.category == 1) {
        this.setAvg();
      } else if (this.search.category == 2) {
        this.setRatios();
      } else if (this.search.category == 3) {
        this.setTopSections();
      } else if (this.search.category == 4) {
        this.setOther();
      }
    },
    async getClassNumList() {
      const res = await getstatclassoption({
        id: this.$route.query.id,
        subjectId: this.search.subjectId,
        viewSchoolId: this.$route.query.schoolId,
      });
      this.classNumList = res.data.data.map((item) => item.name);
      this.checkClassNum = res.data.data.map((item) => item.name);
      this.$refs.classNums.value = this.checkClassNum;
      this.getData();
    },
    async getstateleccateoptionData() {
      const res = await getstateleccateoption({
        id: this.$route.query.id,
        subjectId: this.search.subjectId,
        viewSchoolId: this.$route.query.schoolId,
      });
      this.eleccateList = res.data.data;
      if (res.data.data.length) {
        this.search.cate = res.data.data[0].id;
        this.getEleccombList();
      }
    },
    async getCondition(loadingInstance, io) {
      const res = await getstatsubjectoption({
        id: this.$route.query.id,
        viewSchoolId: this.$route.query.schoolId,
        hasTotal: 1,
        requestPage: 1,
      });
      this.subjectList = res.data.data;
      if (this.subjectList.length) {
        this.search.subjectId = res.data.data[0].id;
        this.getstateleccateoptionData();
      }
      this.closeLoading(loadingInstance, io);
    },
    async getEleccombList() {
      const res = await getstateleccomboptionV2({
        id: this.$route.query.id,
        subjectId: this.search.subjectId == "0" ? null : this.search.subjectId,
        eleccateId: this.search.cate,
        viewSchoolId: this.$route.query.schoolId,
        hasAll: 0,
      });
      this.resEleccombList = res.data.data;
      this.allChecked = true;
      this.resEleccombIds = this.resEleccombList.map((item) => item.id);
      this.getClassNumList();
    },
    closeLoading(loadingInstance, io) {
      if (loadingInstance && io) {
        this.$nextTick(() => {
          loadingInstance.close();
          io.unobserve(this.$refs.classCompetitiveness);
        });
      }
    },
    handleClick(item) {
      this.search.subjectId = item.id;
      if (item.id === 8 || item.id === 4) {
        this.search.cate = item.id;
        this.cateDisabled = true;
        this.search.dataSource = 1;
      } else {
        this.cateDisabled = false;
        this.search.cate = 0;
        this.search.dataSource = 1;
      }
      if (this.search.subjectId == 0 && this.search.category == 3) {
        this.search.category = 1;
      }
      this.search.cate = null;
      this.getstateleccateoptionData();
      // this.setColumns();
      // this.tableKey = Math.random();
    },
    classNumChange(val) {
      this.checkClassNum = val;
      this.getData();
    },
    handleChange() {
      this.getEleccombList();
    },
    resEleccomChange(checkedList) {
      this.resEleccombIds = checkedList;
      this.getData();
    },
    async getData() {
      const res = await classcompetitive({
        id: this.$route.query.id,
        subjectId: this.search.subjectId,
        eleccombs: this.resEleccombIds.join(","),
        classNums: this.checkClassNum.join(","),
        viewSchoolId: this.$route.query.schoolId,
        dataSource: this.search.dataSource,
      });
      this.tableData = res.data.data;
      if (res.data.data.length) {
        this.ratios = res.data.data[0].ratios;
        this.topSections = res.data.data[0].topSections || [];
      }
      this.categoryChange();
    },
    categoryChange() {
      this.defineOptions = null;
      if (this.search.category == 1) {
        this.setAvg();
      } else if (this.search.category == 2) {
        this.selectOptions = this.ratios.map((item) => ({
          label: item.name,
          value: item.name,
        }));
        if (this.ratios.length) {
          this.selectValue = this.ratios[0].name;
        }
        this.setRatios();
      } else if (this.search.category == 3) {
        this.selectOptions = this.topSections.map((item) => ({
          label: item.name,
          value: item.name,
        }));
        if (this.topSections.length) {
          this.selectValue = this.topSections[0].name;
        }
        this.setTopSections();
      } else if (this.search.category == 4) {
        this.selectOptions = this.otherOptions;
        this.selectValue = this.otherOptions[0].value;
        this.setOther();
      }
    },
    setAvg() {
      let classRankCols = [];
      if (this.$parent.topData.isSchoolDisplayReportClassRank == 1) {
        classRankCols = [
          {
            dataIndex: "unionRank",
            align: "center",
            slots: { title: "unionRank" },
            customRender: (text, record) => {
              if (
                this.$parent.topData.isSchoolDisplayReportUnionAverageScore == 0
              )
                return text;
              if (["上位班级", "下位班级"].includes(record.classNum)) {
                return {
                  children: record.classNum,
                  attrs: { colSpan: 3 },
                };
              }
              if (record.classNum == "整体高位班级") {
                return {
                  children: record.classNum,
                  attrs: { colSpan: 4 },
                };
              }
              if (
                [
                  "学校（大类）",
                  "整体（大类）",
                  "学校（单科）",
                  "整体（单科）",
                ].includes(record.classNum)
              ) {
                return {
                  children: record.classNum,
                  attrs: { colSpan: 5 },
                };
              }

              return this.search.dataSource == 1
                ? record.unionRankOri
                : record.unionRank;
            },
          },
          {
            dataIndex: "schoolRank",
            align: "center",
            slots: { title: "schoolRank" },
            customRender: (text, record) => {
              if (
                this.$parent.topData.isSchoolDisplayReportUnionAverageScore == 0
              )
                return text;

              if (["上位班级", "下位班级"].includes(record.classNum)) {
                return {
                  children: record.classNum,
                  attrs: { colSpan: 0 },
                };
              }
              if (
                [
                  "整体高位班级",
                  "学校（大类）",
                  "整体（大类）",
                  "学校（单科）",
                  "整体（单科）",
                ].includes(record.classNum)
              ) {
                return {
                  children: record.classNum,
                  attrs: { colSpan: 0 },
                };
              }
              return this.search.dataSource == 1
                ? record.schoolRankOri
                : record.schoolRank;
            },
          },
        ];
      }
      this.columns = [
        ...classRankCols,
        {
          title: "班级",
          dataIndex: "classNum",
          align: "center",
          customRender: (text, record) => {
            if (
              this.$parent.topData.isSchoolDisplayReportUnionAverageScore == 0
            )
              return text;
            if (["上位班级", "下位班级"].includes(record.classNum)) {
              return {
                children: record.classNum,
                attrs: { colSpan: 1 },
              };
            } else if (record.classNum == "整体高位班级") {
              return {
                children: record.classNum,
                attrs: {
                  colSpan:
                    this.$parent.topData.isSchoolDisplayReportClassRank == 0
                      ? 2
                      : 0,
                },
              };
            } else if (
              [
                "学校（大类）",
                "整体（大类）",
                "学校（单科）",
                "整体（单科）",
              ].includes(record.classNum)
            ) {
              return {
                children: record.classNum,
                attrs: {
                  colSpan:
                    this.$parent.topData.isSchoolDisplayReportClassRank == 0
                      ? 3
                      : 0,
                },
              };
            } else {
              return text;
            }
          },
        },
        {
          title: this.search.subjectId == 0 ? "班主任" : "任课教师",
          dataIndex: "teacherName",
          align: "center",
          customRender: (text, record) => {
            if (
              this.$parent.topData.isSchoolDisplayReportUnionAverageScore == 0
            )
              return text;
            if (["上位班级", "下位班级"].includes(record.classNum)) {
              return {
                children: "-",
                attrs: { colSpan: 1 },
              };
            } else if (
              [
                "整体高位班级",
                "学校（大类）",
                "整体（大类）",
                "学校（单科）",
                "整体（单科）",
              ].includes(record.classNum)
            ) {
              return {
                children: record.classNum,
                attrs: { colSpan: 0 },
              };
            } else {
              return text;
            }
          },
        },
        {
          title: "选考组合",
          dataIndex: "eleccombName",
          align: "center",
          customRender: (text, record) => {
            if (
              this.$parent.topData.isSchoolDisplayReportUnionAverageScore == 0
            )
              return text;
            if (
              [
                "学校（大类）",
                "整体（大类）",
                "学校（单科）",
                "整体（单科）",
              ].includes(record.classNum)
            ) {
              return {
                children: record.classNum,
                attrs: { colSpan: 0 },
              };
            } else {
              return text;
            }
          },
        },
        {
          title: "统计人数",
          dataIndex: "statTotal",
          align: "center",
        },
        {
          dataIndex: "averageScoreOri",
          align: "center",
          slots: { title: "averageScoreOri" },
          customRender: (text, record) =>
            this.search.dataSource == 1
              ? record.averageScoreOri
              : record.averageScore,
        },

        {
          dataIndex: "medianOri",
          align: "center",
          slots: { title: "medianOri" },
          customRender: (text, record) =>
            this.search.dataSource == 1 ? record.medianOri : record.median,
        },
        {
          dataIndex: "highestScoreOri",
          align: "center",
          slots: { title: "highestScoreOri" },
          customRender: (text, record) =>
            this.search.dataSource == 1
              ? record.highestScoreOri
              : record.highestScore,
        },
        {
          dataIndex: "lowestScoreOri",
          align: "center",
          slots: { title: "lowestScoreOri" },
          customRender: (text, record) =>
            this.search.dataSource == 1
              ? record.lowestScoreOri
              : record.lowestScore,
        },
      ];
      const union =
        this.tableData.find((item) =>
          ["学校（大类）", "学校（单科）"].includes(item.classNum)
        ) || {};
      const school =
        this.tableData.find((item) =>
          ["整体（大类）", "整体（单科）"].includes(item.classNum)
        ) || {};
      const notTotal = this.tableData.filter(
        (item) =>
          ![
            "整体（大类）",
            "学校（大类）",
            "整体（单科）",
            "学校（单科）",
          ].includes(item.classNum)
      );
      this.defineOptions = {
        grid: {
          containLabel: true,
        },
        color: [
          "#2474ED",
          "#93BEFD",
          "#5D7092",
          "#5AD8A6",
          "#E8684A",
          "#F6BD16",
        ],
        xAxis: [
          {
            type: "category",
            data: notTotal.map(
              (item) => item.classNum + "/" + item.eleccombName
            ),
            axisPointer: {
              type: "shadow",
            },
            axisLabel: {
              rotate: 25,
              show: true,
              textStyle: {
                color: "rgba(0, 0, 0, 0.45)",
              },
            },
            axisTick: {
              show: false,
            },
          },
        ],
      };
      this.series = [
        {
          type: "bar",
          barMaxWidth: 40,
          name: `班级(${this.search.dataSource == 1 ? "原始分" : "赋分"})`,
          label: {
            position: "top",
            show: true,
            color: "inherit",
            fontSize: 9,
          },
          data: notTotal.map((item) =>
            this.search.dataSource == 1
              ? item.averageScoreOri
              : item.averageScore
          ),
        },

        {
          type: "line",
          name: `学校(${this.search.dataSource == 1 ? "原始分" : "赋分"})`,
          smooth: true,
          data: notTotal.map(() =>
            this.search.dataSource == 1
              ? school.averageScoreOri
              : school.averageScore
          ),
        },
      ];
      if (this.$parent.topData.isSchoolDisplayReportUnionAverageScore == 1) {
        this.series.push({
          type: "line",
          name: `整体(${this.search.dataSource == 1 ? "原始分" : "赋分"})`,
          smooth: true,
          data: notTotal.map(() =>
            this.search.dataSource == 1
              ? union.averageScoreOri
              : union.averageScore
          ),
        });
      }
      this.tableKey = Math.random();
    },
    setRatios() {
      this.columns = [
        {
          dataIndex: "classNum",
          title: "班级",
          align: "center",
          width: 85,
          customRender: (text, record) => {
            if (["整体高位班级", "学科高位班级"].includes(record.classNum)) {
              return {
                children: record.classNum,
                attrs: { colSpan: 2 },
              };
            } else if (
              [
                "学校（大类）",
                "整体（大类）",
                "学校（单科）",
                "整体（单科）",
                "学校（单科）",
                "整体（单科）",
              ].includes(record.classNum)
            ) {
              return {
                children: record.classNum,
                attrs: { colSpan: 3 },
              };
            } else {
              return text;
            }
          },
        },
        {
          dataIndex: "teacherName",
          title: this.search.subjectId == 0 ? "班主任" : "任课教师",
          width: 100,
          align: "center",
          customRender: (text, record) => {
            if (
              [
                "学校（大类）",
                "整体（大类）",
                "学校（单科）",
                "整体（单科）",
                "整体高位班级",
                "学科高位班级",
              ].includes(record.classNum)
            ) {
              return {
                children: record.classNum,
                attrs: { colSpan: 0 },
              };
            } else {
              return text;
            }
          },
        },
        {
          dataIndex: "eleccombName",
          title: "选考组合",
          width: 100,

          align: "center",
          customRender: (text, record) => {
            if (
              [
                "学校（大类）",
                "整体（大类）",
                "学校（单科）",
                "整体（单科）",
              ].includes(record.classNum)
            ) {
              return {
                children: record.classNum,
                attrs: { colSpan: 0 },
              };
            } else {
              return text;
            }
          },
        },
        {
          dataIndex: "statTotal",
          title: "统计人数",
          width: 100,
          align: "center",
        },
        ...this.ratios.map((item, index) => {
          return {
            title: item.name,
            align: "center",
            dataIndex: "ratios" + index,
            children: [
              {
                title: "人数",
                dataIndex: "ratios" + index + "total",
                align: "center",
                customRender: (text, record) =>
                  record.ratios[index][
                    this.search.dataSource == 1 ? "totalOri" : "total"
                  ],
              },
              {
                title: "占比（%）",
                dataIndex: "ratios" + index + "proportion",
                align: "center",
                customRender: (text, record) =>
                  record.ratios[index][
                    this.search.dataSource == 1 ? "proportionOri" : "proportion"
                  ],
              },
              ...(this.$parent.topData.isSchoolDisplayReportClassRank == 1
                ? [
                    {
                      title: "整体排名",
                      dataIndex: "ratios" + index + "unionRank",
                      align: "center",
                      customRender: (text, record) =>
                        record.ratios[index]?.[
                          this.search.dataSource == 1
                            ? "unionRankOri"
                            : "unionRank"
                        ],
                    },
                    {
                      title: "校排名",
                      dataIndex: "ratios" + index + "rank",
                      align: "center",
                      customRender: (text, record) =>
                        record.ratios[index]
                          ? record.ratios[index][
                              this.search.dataSource == 1 ? "rankOri" : "rank"
                            ]
                          : "",
                    },
                  ]
                : []),
            ],
          };
        }),
      ];
      const school =
        this.tableData.find((item) =>
          ["学校（大类）", "学校（单科）"].includes(item.classNum)
        ) || {};
      const union =
        this.tableData.find((item) =>
          ["整体（大类）", "整体（单科）"].includes(item.classNum)
        ) || {};
      const notTotal = this.tableData.filter(
        (item) =>
          ![
            "学校（大类）",
            "整体（大类）",
            "学校（单科）",
            "整体（单科）",
          ].includes(item.classNum)
      );
      this.defineOptions = {
        grid: {
          containLabel: true,
        },
        color: [
          "#2474ED",
          "#93BEFD",
          "#5D7092",
          "#5AD8A6",
          "#E8684A",
          "#F6BD16",
        ],
        xAxis: [
          {
            type: "category",
            data: notTotal.map(
              (item) => item.classNum + "/" + item.eleccombName
            ),
            axisPointer: {
              type: "shadow",
            },
            axisLabel: {
              rotate: 25,
              show: true,
              textStyle: {
                color: "rgba(0, 0, 0, 0.45)",
              },
            },
            axisTick: {
              show: false,
            },
          },
        ],
      };
      this.series = [
        {
          type: "bar",
          barMaxWidth: 40,
          name: `班级`,
          label: {
            position: "top",
            show: true,
            color: "inherit",
            fontSize: 9,
          },
          data: notTotal.map((item) => {
            const data = item.ratios.find((i) => i.name == this.selectValue);
            return this.search.dataSource == 1 ? data.totalOri : data.total;
          }),
        },

        {
          type: "line",
          name: `学校`,
          smooth: true,
          data: notTotal.map(() => {
            if (school.ratios) {
              const data = school.ratios.find(
                (i) => i.name == this.selectValue
              );
              return this.search.dataSource == 1 ? data.totalOri : data.total;
            }
            return null;
          }),
        },
      ];
      if (this.$parent.topData.isSchoolDisplayReportUnionAverageScore == 1) {
        this.series.push({
          type: "line",
          name: `整体`,
          smooth: true,
          data: notTotal.map(() => {
            if (union.ratios) {
              const data = union.ratios.find((i) => i.name == this.selectValue);
              return this.search.dataSource == 1 ? data.totalOri : data.total;
            }
            return null;
          }),
        });
      }
      this.tableKey = Math.random();
    },
    setTopSections() {
      this.columns = [
        {
          dataIndex: "classNum",

          title: "班级",
          width: 85,
          align: "center",
          customRender: (text, record) => {
            if (["整体高位班级", "学科高位班级"].includes(record.classNum)) {
              return {
                children: record.classNum,
                attrs: { colSpan: 2 },
              };
            } else if (
              [
                "学校（大类）",
                "整体（大类）",
                "学校（单科）",
                "整体（单科）",
              ].includes(record.classNum)
            ) {
              return {
                children: record.classNum,
                attrs: { colSpan: 3 },
              };
            } else {
              return text;
            }
          },
        },
        {
          dataIndex: "teacherName",
          title: this.search.subjectId == 0 ? "班主任" : "任课教师",
          width: 100,

          align: "center",
          customRender: (text, record) => {
            if (
              [
                "学校（大类）",
                "整体（大类）",
                "整体高位班级",
                "学科高位班级",
                "学校（单科）",
                "整体（单科）",
              ].includes(record.classNum)
            ) {
              return {
                children: record.classNum,
                attrs: { colSpan: 0 },
              };
            } else {
              return text;
            }
          },
        },
        {
          dataIndex: "eleccombName",
          title: "选考组合",
          width: 100,

          align: "center",
          customRender: (text, record) => {
            if (
              [
                "学校（大类）",
                "整体（大类）",
                "学校（单科）",
                "整体（单科）",
              ].includes(record.classNum)
            ) {
              return {
                children: record.classNum,
                attrs: { colSpan: 0 },
              };
            } else {
              return text;
            }
          },
        },
        {
          dataIndex: "statTotal",
          title: "统计人数",
          width: 100,

          align: "center",
        },
        ...this.topSections.map((item, index) => {
          return {
            title: item.name,
            align: "center",
            dataIndex: "topSections" + index,
            children: [
              {
                title: "人数",
                dataIndex: "topSections" + index + "total",
                align: "center",
                customRender: (text, record) => record.topSections[index].total,
              },
              {
                title: "占比（%）",
                dataIndex: "topSections" + index + "proportion",
                align: "center",
                customRender: (text, record) =>
                  record.topSections[index].proportion,
              },
              ...(this.$parent.topData.isSchoolDisplayReportClassRank == 1
                ? [
                    {
                      title: "整体排名",
                      dataIndex: "topSections" + index + "unionRank",
                      align: "center",
                      customRender: (text, record) =>
                        record.topSections[index].unionRank,
                    },
                    {
                      title: "校排名",
                      dataIndex: "topSections" + index + "rank",
                      align: "center",
                      customRender: (text, record) =>
                        record.topSections[index].total,
                    },
                  ]
                : []),
            ],
          };
        }),
      ];
      const school =
        this.tableData.find((item) =>
          ["学校（大类）", "学校（单科）"].includes(item.classNum)
        ) || {};
      const union =
        this.tableData.find((item) =>
          ["整体（大类）", "整体（单科）"].includes(item.classNum)
        ) || {};
      const notTotal = this.tableData.filter(
        (item) =>
          ![
            "学校（大类）",
            "整体（大类）",
            "学校（单科）",
            "整体（单科）",
          ].includes(item.classNum)
      );
      this.defineOptions = {
        grid: {
          containLabel: true,
        },
        color: [
          "#2474ED",
          "#93BEFD",
          "#5D7092",
          "#5AD8A6",
          "#E8684A",
          "#F6BD16",
        ],
        xAxis: [
          {
            type: "category",
            data: notTotal.map(
              (item) => item.classNum + "/" + item.eleccombName
            ),
            axisPointer: {
              type: "shadow",
            },
            axisLabel: {
              rotate: 25,
              show: true,
              textStyle: {
                color: "rgba(0, 0, 0, 0.45)",
              },
            },
            axisTick: {
              show: false,
            },
          },
        ],
      };
      this.series = [
        {
          type: "bar",
          barMaxWidth: 40,
          name: `班级`,
          label: {
            position: "top",
            show: true,
            color: "inherit",
            fontSize: 9,
          },
          data: notTotal.map((item) => {
            const data = item.topSections.find(
              (i) => i.name == this.selectValue
            );
            return this.search.dataSource == 1 ? data.totalOri : data.total;
          }),
        },

        {
          type: "line",
          name: `学校`,
          smooth: true,
          data: notTotal.map(() => {
            if (school.topSections) {
              const data = school.topSections.find(
                (i) => i.name == this.selectValue
              );
              return this.search.dataSource == 1 ? data.totalOri : data.total;
            }
            return null;
          }),
        },
      ];
      if (this.$parent.topData.isSchoolDisplayReportUnionAverageScore == 1) {
        this.series.push({
          type: "line",
          name: `整体`,
          smooth: true,
          data: notTotal.map(() => {
            if (union.topSections) {
              const data = union.topSections.find(
                (i) => i.name == this.selectValue
              );
              return this.search.dataSource == 1 ? data.totalOri : data.total;
            }
            return null;
          }),
        });
      }
      this.tableKey = Math.random();
    },
    setOther() {
      let otherCols = [...this.otherCols];
      if (
        this.$route.query.examType == 1 &&
        this.$parent.topData.isSchoolDisplayReportUnionAverageScore != 1
      ) {
        otherCols = this.otherCols.filter(
          (item) => item.dataIndex != "overAverageRateUnion"
        );
      }
      this.columns = [
        {
          dataIndex: "classNum",

          title: "班级",
          align: "center",
          customRender: (text, record) => {
            if (["整体高位班级", "学科高位班级"].includes(record.classNum)) {
              return {
                children: record.classNum,
                attrs: { colSpan: 2 },
              };
            } else if (
              [
                "学校（大类）",
                "整体（大类）",
                "学校（单科）",
                "整体（单科）",
              ].includes(record.classNum)
            ) {
              return {
                children: record.classNum,
                attrs: { colSpan: 3 },
              };
            } else {
              return text;
            }
          },
        },
        {
          dataIndex: "teacherName",
          title: this.search.subjectId == 0 ? "班主任" : "任课教师",

          align: "center",
          customRender: (text, record) => {
            if (
              [
                "学校（大类）",
                "整体（大类）",
                "学校（单科）",
                "整体（单科）",
                "学科高位班级",
                "整体高位班级",
              ].includes(record.classNum)
            ) {
              return {
                children: record.classNum,
                attrs: { colSpan: 0 },
              };
            } else {
              return text;
            }
          },
        },
        {
          dataIndex: "eleccombName",
          title: "选考组合",

          align: "center",
          customRender: (text, record) => {
            if (
              [
                "学校（大类）",
                "整体（大类）",
                "学校（单科）",
                "整体（单科）",
                "学校（单科）",
                "整体（单科）",
              ].includes(record.classNum)
            ) {
              return {
                children: record.classNum,
                attrs: { colSpan: 0 },
              };
            } else {
              return text;
            }
          },
        },
        {
          dataIndex: "statTotal",
          title: "统计人数",
          align: "center",
        },
        ...this.factoryCol(otherCols),
      ];
      const school =
        this.tableData.find((item) => item.classNum === "学校（大类）") || {};
      const union =
        this.tableData.find((item) => item.classNum === "整体（大类）") || {};
      const notTotal = this.tableData.filter(
        (item) =>
          item.classNum !== "学校（大类）" && item.classNum !== "整体（大类）"
      );
      this.defineOptions = {
        grid: {
          containLabel: true,
        },
        color: [
          "#2474ED",
          "#93BEFD",
          "#5D7092",
          "#5AD8A6",
          "#E8684A",
          "#F6BD16",
        ],
        xAxis: [
          {
            type: "category",
            data: notTotal.map((item) => item.classNum),
            axisPointer: {
              type: "shadow",
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "rgba(0, 0, 0, 0.45)",
              },
            },
            axisTick: {
              show: false,
            },
          },
        ],
      };
      this.series = [
        {
          type: "bar",
          barMaxWidth: 40,
          name: `班级占比`,
          label: {
            position: "top",
            show: true,
            color: "inherit",
            fontSize: 9,
          },
          data: notTotal.map(
            (item) =>
              item[
                this.search.dataSource == 1
                  ? this.selectValue + "Ori"
                  : this.selectValue
              ]
          ),
        },

        {
          type: "line",
          name: `校占比`,
          smooth: true,
          data: notTotal.map(
            () =>
              school[
                this.search.dataSource == 1
                  ? this.selectValue + "Ori"
                  : this.selectValue
              ]
          ),
        },
      ];
      if (this.$parent.topData.isSchoolDisplayReportUnionAverageScore == 1) {
        this.series.push({
          type: "line",
          name: `整体占比`,
          smooth: true,
          data: notTotal.map(
            () =>
              union[
                this.search.dataSource == 1
                  ? this.selectValue + "Ori"
                  : this.selectValue
              ]
          ),
        });
      }
      this.tableKey = Math.random();
    },
    factoryCol(cols) {
      return cols.map((col) => {
        if ([1, 2, 3, 4, 8].includes(this.search.subjectId)) {
          return {
            ...col,
            dataIndex: col.dataIndex + "Ori",
            slots: {
              title: col.dataIndex + "Ori",
            },
          };
        }
        return {
          dataIndex: col.dataIndex + "col",
          align: "center",
          title: col.label,
          children: [
            {
              dataIndex: col.dataIndex + "Ori",
              title: "原始分",
              align: "center",
            },
            {
              dataIndex: col.dataIndex,
              title: "赋分",
              align: "center",
            },
          ],
        };
      });
    },
    titleCase(str) {
      return str.slice(0, 1).toUpperCase() + str.slice(1);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../new-exam.module.scss";
.container {
  .filter-box {
    box-sizing: border-box;
    padding: 16px;
    margin-bottom: 18px;
    border-radius: 8px;
    border: 1px solid #d5d6db;
    .subjectList {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      color: #0a1119;
      font-size: 14px;
      .label {
        margin-right: 8px;
        margin-bottom: 18px;
      }
      .subject {
        border-radius: 4px;
        border: 1px solid #d5d6db;
        box-sizing: border-box;
        padding: 6px 18px;
        cursor: pointer;
        background: #fff;
        margin-bottom: 18px;
        transition: all 0.2s ease-in-out;
      }
      .active {
        color: #fff;
        background: #2474ed;
        border-color: #2474ed;
      }
      .subject + .subject {
        margin-left: 8px;
      }
    }
    .resEleccomb {
      margin-left: 21px;
    }
    .operate {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;

      //   margin-bottom: 18px;
    }
  }
  .category {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 18px;
  }
  .dataSource {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 18px;
  }
}
.checkbox-content {
  display: flex;
  flex-wrap: wrap;
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
  }
  .ant-checkbox-wrapper {
    margin-bottom: 8px;
    margin-right: 8px;
  }
}
.select {
  text-align: right;
  margin-bottom: 24px;
}
</style>
