<template>
  <div ref="highscore" v-lazyChart="{ fn: getData }" class="container">
    <div class="title">高分段人群分布</div>
    <div class="filter">
      <a-radio-group v-model="eleccateSubjectId" button-style="solid">
        <a-radio-button v-if="$parent.topData.hasPhysics == 1" :value="8">
          物理类
        </a-radio-button>
        <a-radio-button v-if="$parent.topData.hasHistory == 1" :value="4">
          历史类
        </a-radio-button>
      </a-radio-group>
      <div class="input">
        自定义高分范围：整体排名前
        <a-input-number
          v-model="rank"
          style="width: 120px; margin: 10px"
          :min="1"
        />名
        <a-button type="primary" @click="getData">确定</a-button>
      </div>
    </div>
    <div class="operate">
      <a-radio-group v-model="type" button-style="solid" @change="typeChange">
        <a-radio-button :value="1"> 人数 </a-radio-button>
        <a-radio-button :value="2"> 占比 </a-radio-button>
      </a-radio-group>
    </div>
    <div class="chart">
      <ChartContainer
        v-if="defineOptions"
        :key="tableKey"
        :define-options="defineOptions"
        :series="series"
      />
      <no-data v-else message="暂无数据"></no-data>
    </div>
    <exTable :key="tableKey" :columns="columns" :data-source="tableData" />
  </div>
</template>

<script>
import ChartContainer from "../components/ChartContainer.vue";
import exTable from "../../components/exTable.vue";
import { highscorestudentdistribution } from "@/core/api/newExamAcademic/union/index";
export default {
  name: "",
  components: {
    ChartContainer,
    exTable,
  },
  data() {
    return {
      eleccateSubjectId: 8,
      defineOptions: null,
      series: [],
      rank: 100,
      type: 1,
      columns: [
        {
          title: "班级名称",
          dataIndex: "classNum",
          align: "center",
          width: 120,
        },
        {
          title: "班主任",
          dataIndex: "teacher",
          align: "center",
          width: 85,
          customRender: (text, record) =>
            ["整体高位班级", "本校", "整体"].includes(record.classNum)
              ? "-"
              : record.teacher,
        },
        {
          title: "高分人群",
          dataIndex: "total",
          align: "center",
          width: 120,
        },
        {
          title: "占比",
          dataIndex: "proportion",
          align: "center",
          width: 120,
          customRender: (text, record) =>
            record.proportion === null ? "" : record.proportion + "%",
        },
        {
          title: "学生名单",
          dataIndex: "students",
          align: "center",
          customRender: (text, record) => {
            if (record.students) {
              let data =
                record.extend || record.students.length <= 9
                  ? record.students
                  : record.students.slice(0, 9);

              let button;
              if (record.students.length > 9) {
                button = record.extend ? (
                  <a-button
                    type="link"
                    vOn:click={() => this.$set(record, "extend", false)}
                  >
                    收起
                  </a-button>
                ) : (
                  <a-button
                    type="link"
                    vOn:click={() => this.$set(record, "extend", true)}
                  >
                    查看更多
                  </a-button>
                );
              }
              return (
                <div class="nameList">
                  {...data.map((item) => (
                    <a-button
                      type="link"
                      vOn:click={() => this.handleClick(record, item)}
                    >
                      {item.name}
                    </a-button>
                  ))}
                  {button}
                </div>
              );
            }
          },
        },
      ],
      tableData: [],
      tableKey: Math.random(),
    };
  },
  created() {
    this.eleccateSubjectId = this.$parent.topData.hasPhysics == 1 ? 8 : 4;
  },
  methods: {
    typeChange() {
      this.defineOptions = {
        grid: {
          left: 0,
          right: 0,
          containLabel: true,
        },
        color: [
          "#2474ED",
          "#93BEFD",
          "#5D7092",
          "#5AD8A6",
          "#E8684A",
          "#F6BD16",
        ],
        xAxis: [
          {
            type: "category",
            data: this.tableData.map((item) => item.classNum),
            axisPointer: {
              type: "shadow",
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "rgba(0, 0, 0, 0.45)",
              },
            },
            axisTick: {
              show: false,
            },
          },
        ],
      };
      this.series = [
        {
          type: "bar",
          barMaxWidth: 40,
          label: {
            position: "top",
            show: true,
            color: "inherit",
            fontSize: 9,
            formatter: ({ value }) => {
              if (value !== null) {
                return this.type != 1 ? value + "%" : value;
              }
            },
          },
          data: this.tableData.map((item) =>
            this.type == 1 ? item.total : item.proportion
          ),
        },
      ];
      this.tableKey = Math.random();
    },
    handleClick(record, item) {
      const { href } = this.$router.resolve({
        path: "/academic/new-exam/student-report",
        query: {
          ...this.$route.query,
          studentName: item.name,
          studentId: item.studentId,
          subjectId: 0,
          classNum: record.classNum,
          level: this.$route.query.level,
        },
      });
      let timer = setTimeout(() => {
        window.open(href, "_blank");
        clearTimeout(timer);
      }, 0);
    },
    async getData(loadingInstance, io) {
      try {
        const res = await highscorestudentdistribution({
          id: this.$route.query.id,
          viewSchoolId: this.$route.query.schoolId,
          rank: this.rank,
          eleccateSubjectId: this.eleccateSubjectId,
        });
        if (this.$parent.topData.isSchoolDisplayReportUnionAverageScore == 1) {
          this.tableData = res.data.data;
        } else {
          this.tableData = res.data.data.filter(
            (item) => item.classNum !== "整体"
          );
        }
        this.typeChange();

        this.closeLoading(loadingInstance, io);
      } catch {
        this.closeLoading(loadingInstance, io);
      }
    },
    closeLoading(loadingInstance, io) {
      if (loadingInstance && io) {
        this.$nextTick(() => {
          loadingInstance.close();
          io.unobserve(this.$refs.highscore);
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../new-exam.module.scss";
.filter {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 24px;
  .input {
    margin-left: 24px;
  }
}
.operate {
  text-align: right;
  margin-bottom: 24px;
}
.chart {
  margin-bottom: 24px;
}
.nameList {
  text-align: left;
}
</style>
