<template>
  <div class="stat-contain">
    <div class="topCard">
      <tag define-tag>{{ schoolName }}</tag>
      <div class="title-box">
        <div class="title">
          <div class="new-exam-tag">新高考</div>
          {{ topData.name }}
          <div
            :class="['sub-tag', topData.type == 99 ? 'otherTitle' : 'general']"
          >
            {{ getExamType(topData.type) }}
          </div>
        </div>
        <!-- <el-button type="primary" plain size="mini" @click="exportPDF"
          >导出PDF</el-button
        > -->
      </div>
      <div class="back" @click="back">
        <i class="el-icon-arrow-left"></i>返回
      </div>
      <div class="sub-title">
        <!-- <div>组织单位：文通联考平台（一）</div> -->
        <div>
          年级：<span v-if="level == 2">初</span
          ><span v-if="level == 3">高</span>{{ topData.year }}级
        </div>
        <div>时间：{{ topData.examDate }}</div>
      </div>
      <div class="model-title">联考报告</div>
      <div class="new-dashboard">
        <div
          :class="[
            'box',
            'normal',
            topData.hasPhysics != 1 || topData.hasHistory != 1
              ? 'flex-box'
              : '',
          ]"
        >
          <div class="item border-item">
            <div class="item-title">{{ topData.statTotal }}<span>名</span></div>
            <div>参统考生</div>
          </div>
          <div class="item">
            <div class="item-title">
              {{ topData.eleccombCount }}<span>个</span>
            </div>
            <div>参考组合</div>
          </div>
        </div>
        <div
          v-if="topData.isSchoolDisplayReportSchoolRank == 1"
          :class="[
            'box',
            'normal',
            topData.hasPhysics != 1 || topData.hasHistory != 1
              ? 'flex-box'
              : '',
          ]"
        >
          <div v-if="topData.hasPhysics == 1" class="item border-item">
            <div class="item-title">{{ topData.physicsRank }}</div>
            <div>物理类</div>
          </div>
          <div v-if="topData.hasHistory == 1" class="item">
            <div class="item-title">
              {{ topData.historyRank }}
            </div>
            <div>历史类</div>
          </div>
        </div>
        <div
          v-if="topData.hasPhysics == 1 || topData.hasHistory == 1"
          class="vertical-text"
          style="background: #f3fcf8"
        >
          平 均 分
        </div>
        <div
          v-if="topData.hasPhysics == 1 || topData.hasHistory == 1"
          :class="[
            'box',
            'success',
            topData.hasPhysics != 1 || topData.hasHistory != 1
              ? 'flex-box'
              : '',
          ]"
        >
          <template v-if="topData.hasPhysics == 1">
            <div
              :class="[
                'item',
                topData.historyMaxScoreOri && topData.historyMaxScore
                  ? 'border-item'
                  : '',
              ]"
            >
              <div class="flex">
                <div class="sub-item sub-border-item">
                  <div class="item-title">
                    {{ topData.physicsAverageScoreOri || "-" }}
                  </div>
                  <div>原始分</div>
                </div>
                <div class="sub-item">
                  <div class="item-title">
                    {{ topData.physicsAverageScore || "-" }}
                  </div>
                  <div>赋分</div>
                </div>
              </div>
              <div>物理类</div>
            </div>
          </template>
          <template v-if="topData.hasHistory == 1">
            <div class="item">
              <div class="flex">
                <div class="sub-item sub-border-item">
                  <div class="item-title">
                    {{ topData.historyAverageScoreOri || "-" }}
                  </div>
                  <div>原始分</div>
                </div>
                <div class="sub-item">
                  <div class="item-title">
                    {{ topData.historyAverageScore || "-" }}
                  </div>
                  <div>赋分</div>
                </div>
              </div>
              <div>历史类</div>
            </div>
          </template>
        </div>
        <div
          v-if="topData.hasPhysics == 1 || topData.hasHistory == 1"
          class="vertical-text"
        >
          最 高 分
        </div>
        <div
          v-if="topData.hasPhysics == 1 || topData.hasHistory == 1"
          :class="[
            'box',
            'other',
            topData.hasPhysics != 1 || topData.hasHistory != 1
              ? 'flex-box'
              : '',
          ]"
        >
          <template v-if="topData.hasPhysics == 1">
            <div
              :class="[
                'item',
                topData.historyMaxScoreOri && topData.historyMaxScore
                  ? 'border-item'
                  : '',
              ]"
            >
              <div class="flex">
                <div class="sub-item sub-border-item">
                  <div class="item-title">
                    {{ topData.physicsMaxScoreOri || "-" }}
                  </div>
                  <div>原始分</div>
                </div>
                <div class="sub-item">
                  <div class="item-title">
                    {{ topData.physicsMaxScore || "-" }}
                  </div>
                  <div>赋分</div>
                </div>
              </div>
              <div>物理类</div>
            </div>
          </template>
          <template v-if="topData.hasHistory == 1">
            <div class="item">
              <div class="flex">
                <div class="sub-item sub-border-item">
                  <div class="item-title">
                    {{ topData.historyMaxScoreOri || "-" }}
                  </div>
                  <div>原始分</div>
                </div>
                <div class="sub-item">
                  <div class="item-title">
                    {{ topData.historyMaxScore || "-" }}
                  </div>
                  <div>赋分</div>
                </div>
              </div>
              <div>历史类</div>
            </div>
          </template>
        </div>
        <template v-if="$route.query.examType != 1">
          <div
            v-if="topData.hasPhysics == 1 || topData.hasHistory == 1"
            class="vertical-text"
            style="background: #f3f8ff"
          >
            高 分 率
          </div>
          <div
            v-if="topData.hasPhysics == 1 || topData.hasHistory == 1"
            :class="[
              'box',
              'normal',
              topData.hasPhysics != 1 || topData.hasHistory != 1
                ? 'flex-box'
                : '',
            ]"
          >
            <template v-if="topData.hasPhysics == 1">
              <div
                :class="[
                  'item',
                  topData.historyMaxScoreOri && topData.historyMaxScore
                    ? 'border-item'
                    : '',
                ]"
              >
                <div class="flex">
                  <div class="sub-item sub-border-item">
                    <div class="item-title">
                      {{ topData.physicsMaxScoreOri || "-" }}
                    </div>
                    <div>原始分</div>
                  </div>
                  <div class="sub-item">
                    <div class="item-title">
                      {{ topData.physicsMaxScore || "-" }}
                    </div>
                    <div>赋分</div>
                  </div>
                </div>
                <div>物理类</div>
              </div>
            </template>
            <template v-if="topData.hasHistory == 1">
              <div class="item">
                <div class="flex">
                  <div class="sub-item sub-border-item">
                    <div class="item-title">
                      {{ topData.historyMaxScoreOri || "-" }}
                    </div>
                    <div>原始分</div>
                  </div>
                  <div class="sub-item">
                    <div class="item-title">
                      {{ topData.historyMaxScore || "-" }}
                    </div>
                    <div>赋分</div>
                  </div>
                </div>
                <div>历史类</div>
              </div>
            </template>
          </div>
        </template>
      </div>
    </div>
    <template v-if="topData.year">
      <Branch
        v-if="totalSegment"
        id="Branch"
        :top-data="topData"
        :total-segment="totalSegment"
      ></Branch>
      <Subject id="Subject"></Subject>
      <classVue v-if="topData.statClass == 1" id="classVue"></classVue>
      <balance v-if="topData.statClass == 1" id="balance"></balance>
      <highScore v-if="topData.statClass == 1" id="highScore"></highScore>
      <development id="development"></development>
    </template>
    <pageNav :menu-list="menuList" />
  </div>
</template>
<script>
import { examTypeOptions } from "@/core/util/constdata";
import tag from "../../academic/components/tag";
import pageNav from "@/views/academic/components/pageNav.vue";
import Branch from "./branch/index";
import Subject from "./subject/index.vue";
import classVue from "./class/index.vue";
import balance from "./balance/index.vue";
import highScore from "./highScore/index.vue";
import development from "./development/index.vue";
import { getunionreporttopinfo } from "@/core/api/newExamAcademic/union/index.js";
import { getstatsetting } from "@/core/api/academic/statistics.js";
export default {
  components: {
    tag,
    Branch,
    Subject,
    classVue,
    balance,
    highScore,
    development,
    pageNav,
  },
  data() {
    return {
      schoolName: null,
      topData: {
        hasPhysics: 1,
        hasHistory: 1,
      },
      menuList: [
        {
          id: "Branch",
          name: "成绩分布",
        },
        {
          id: "Subject",
          name: "学科竞争力",
        },
        {
          id: "classVue",
          name: "班级竞争力",
        },
        {
          id: "highScore",
          name: "高分群体",
        },
        {
          id: "development",
          name: "发展性分析",
        },
      ],
      totalSegment: null,
      level: null,
    };
  },
  mounted() {
    this.getstatsetting();
    this.getTopInfo();
    this.schoolName = this.$route.query.schoolName;
  },
  methods: {
    async getstatsetting() {
      const res = await getstatsetting({ id: this.$route.query.id });
      this.totalSegment = res.data.data.statSegmentSettings.find(
        (item) => item.subjectId == 0
      );
    },
    back() {
      this.$router.go(-1);
    },
    async getTopInfo() {
      const res = await getunionreporttopinfo({
        id: this.$route.query.id,
        viewSchoolId: this.$route.query.schoolId,
      });
      this.topData = { ...res.data.data };
      if (this.topData.statClass == 0) {
        this.menuList = this.menuList.filter(
          (item) => !["classVue", "highScore"].includes(item.id)
        );
      }
    },
    getExamType(type) {
      if (type) {
        const data = examTypeOptions.find((item) => item.value === type);
        return data.label;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../newExamAcademic/new-exam.module.scss";
.reportDetail {
  margin: 0 auto;
  width: 1200px;
  .charts-box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 1220px;
    margin-right: -20px;
    // justify-content: space-between;
    .chart-item {
      width: 590px;
      background: #ffffff;
      box-shadow: 0px 0px 5px 0px rgba(231, 231, 231, 0.7);
      height: 350px;
      margin: 20px 20px 0 0;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      .item-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 0 20px;
        font-size: 16px;
        border-bottom: 1px solid #e6e6e6;
        max-height: 46px;
        min-height: 46px;
        .title {
          color: #333333;
          font-size: 16px;
        }
        .detail {
          font-size: 14px;
          color: #999999;
          text-align: right;
          cursor: pointer;
          i {
            display: inline-block;
            vertical-align: middle;
            width: 10px;
            height: 12px;
          }
        }
      }
      .content {
        width: 550px;
        height: 100%;
        margin: 5px auto;
        box-sizing: border-box;
        // padding-bottom: 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        .chart {
          display: flex;
          align-items: flex-start;
          width: 100%;
          height: 100%;
        }
        .chart-title {
          padding: 0 20px;
          margin-top: 12px;
          font-size: 12px;
          text-align: center;
          color: #999;
          position: absolute;
          bottom: 5px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
}
.new-dashboard {
  display: flex;
  align-items: center;
  margin-top: 18px;

  .box {
    box-sizing: border-box;
    padding: 14px 12px;
    color: #3e4551;
    display: flex;
    height: 88px;
    align-items: center;
    text-align: center;
    border-radius: 8px;
    font-size: 12px;
    flex: 1;
    justify-content: space-between;
    .item {
      flex: 1;
    }
    .item + .item {
      margin-left: 24px;
    }
  }

  .box + .box {
    margin-left: 24px;
  }

  .vertical-text + .box {
    margin-left: 8px;
  }
}
.flex-box {
  margin: 0 auto;
  flex: 1;
  .item {
    width: 100%;
    .flex {
      justify-content: center;
      .sub-item {
        flex: 1;
      }
    }
  }
}
.normal {
  background-color: #f3f8ff;

  .item-title {
    color: #2474ed;
    font-size: 18px;
    margin-bottom: 8px;
    line-height: 1;

    span {
      font-size: 12px;
    }
  }

  .border-item {
    border-right: 1px dashed #d2e2f9;
    padding-right: 12px;
    box-sizing: border-box;
  }
}

.other {
  background-color: #fff8f3;

  .item-title {
    color: #ff7a05;
    font-size: 18px;
    margin-bottom: 8px;
    line-height: 1;

    span {
      font-size: 12px;
    }
  }
  .border-item {
    border-right: 1px dashed #f3bd96;
    padding-right: 12px;
    box-sizing: border-box;
  }
}

.success {
  background-color: #f3fcf8;

  .item-title {
    color: #2bb472;
    font-size: 18px;
    margin-bottom: 8px;
    line-height: 1;

    span {
      font-size: 12px;
    }
  }
  .border-item {
    border-right: 1px dashed #c2e9d8;
    padding-right: 12px;
    box-sizing: border-box;
  }
}
.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.vertical-text {
  writing-mode: vertical-lr;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  background: #fff8f3;
  border-radius: 8px;
  margin-left: 12px;
  height: 88px;
}
.sub-item {
  font-size: 12px;
  margin-bottom: 8px;
  .item-title {
    margin-bottom: 8px !important;
  }
}
.sub-border-item {
  border-right: 1px dashed #fee2ce;
  padding-right: 12px;
  box-sizing: border-box;
}
.sub-item + .sub-item {
  margin-left: 12px;
}
.otherTitle {
  background-color: #fff;
}
.new-exam-tag {
  color: #ff7a00;
  font-size: 14px;
  box-sizing: border-box;
  background: #fff8f3;
  border-radius: 4px;
  padding: 6px 8px;
  text-align: center;
  min-width: 0 !important;
  line-height: 1;
  margin-right: 4px;
}
ul {
  margin-bottom: 0;
}
</style>
